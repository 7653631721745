import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@material-ui/core'
import { useAxios } from '../../utils/hooks'
import { useHistory } from 'react-router'
import { useDebounce } from 'use-debounce'
import {
  getCurrentRole,
  getSubscribersFrom,
  getSubscribersFilter,
  setIsLoading,
  setSubscribersFrom,
  setSubscribersFilter,
  getSellers,
  getAccountants,
  getSubscribersPerPage,
  setSubscribersPerPage,
  setSellers,
  setAccountants
} from '../mainReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { useApi } from '../../api/api'

const useStyles = makeStyles({
  activeExpireSoon: {
    '& td': { color: '#ffad49' },
  },
  active: {
    '& td': { color: '#fd2020' },
  },
})

export default function Subscribers () {
  const classes = useStyles()
  const history = useHistory()
  const [subscribers, setSubscribers] = useState([])
  const [subscribersCount, setSubscribersCount] = useState(0)
  const subscribersFrom = useSelector(getSubscribersFrom)
  const rowsPerPage = useSelector(getSubscribersPerPage)
  const subscribersFilter = useSelector(getSubscribersFilter)
  const sellers = useSelector(getSellers)
  const accountants = useSelector(getAccountants)
  const [searchTerm, setSearchTerm] = useState('')
  const [term] = useDebounce(searchTerm, 500)
  const dispatch = useDispatch()
  const currentRole = useSelector(getCurrentRole)

  const api = useApi()

  const enusCount = subscribers.reduce((acc, curr) => {
    acc = {
      active: acc.active + curr.enus.filter(i => i.active).length,
      total: acc.total + curr.enus.length
    }
    return acc
  }, {active: 0, total: 0})

  const axiosInstance = useAxios()
  const getSubscribers = useCallback(() => {
    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(`/admin/subscriber/_search?from=${subscribersFrom}&size=${rowsPerPage}${subscribersFilter.seller ? '&seller=' + subscribersFilter.seller : ''}${subscribersFilter.accountant ? '&accountant=' + subscribersFilter.accountant : ''}`, {
        term: term || '',
        role: currentRole,
        sort: 'activeDueDate',
        order: 'asc'
      })
        .then(response => {
          const subscribersFromResponse = response.data.subscribers.map(s => {
            return {
              ...s,
              sellerName: s.seller ? s.seller.name : '',
              enus: response.data.subscriberEnuListHashMap && response.data.subscriberEnuListHashMap[s.id] ? response.data.subscriberEnuListHashMap[s.id] : [],
              details: {
                phone: s.phone,
                note: s.note,
                bankAccounts: s.bankAccounts
              }
            }
          })

          setSubscribers(subscribersFromResponse)
          setSubscribersCount(response.data.metadata.hits)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [dispatch, axiosInstance, subscribersFrom, term, currentRole, rowsPerPage, subscribersFilter])

  // get sellers list if user has ADMIN role, and if sellers list was not already loaded
  useEffect(() => {
    if (['ADMIN'].includes(currentRole) && sellers.length === 0) {
      api.getSellers()
        .then(({ data }) => {
          dispatch(setSellers(data))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [api, sellers, dispatch])

  // get accountants list if user has ADMIN role, and if sellers list was not already loaded
  useEffect(() => {
    if (['ADMIN'].includes(currentRole) && accountants.length === 0) {
      api.getAccountants()
        .then(({ data }) => {
          dispatch(setAccountants(data))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [api, accountants, dispatch])

  useEffect(() => {
    getSubscribers()
  }, [subscribersFrom, getSubscribers, term])

  const handleChangePage = (e, v) => {
    dispatch(setSubscribersFrom(rowsPerPage * (v - 1)))
  }

  const columns = [
    {
      name: 'name',
      label: 'Naziv',
      options: {},
    },
    {
      name: 'taxIdentifier',
      label: 'PIB/JMBG',
      options: {},
    },
    {
      name: 'address',
      label: 'Adresa',
      options: {},
    },
    {
      name: 'town',
      label: 'Grad',
      options: {},
    },
    {
      name: 'responsiblePerson',
      label: 'Odgovorno lice',
      options: {},
    },
    {
      name: 'sellerName',
      label: 'Prodavac',
      options: {},
    },
    {
      name: 'activeDueDate',
      label: 'Aktivan do',
      options: {
        setCellProps: () => ({ style: { width: 90 } }),
        customBodyRender: value => value ? moment(value).format('DD.MM.YYYY.') : null
      },
    },
    {
      name: 'active',
      label: ' ',
      options: {
        download: false,
        display: false
      }
    },
    {
      name: 'details',
      label: 'Detalji',
      options: {
        display: false,
        download: false
      },
    },
    {
      name: 'phone',
      label: 'Telefon',
      options: {
        display: false
      },
    },
    {
      name: 'note',
      label: 'Napomena',
      options: {
        display: false
      },
    },
    {
      name: 'bankAccounts',
      label: 'ŽR',
      options: {
        display: false,
        customBodyRender: value => Array.isArray(value) ? value.join('; ') : ''
      },
    },
    {
      name: 'enus',
      label: 'ENU-i',
      options: {
        download: false,
        customBodyRender: value => Array.isArray(value) ? `${value.filter(i => i.active).length}/${value.length}` : '0/0'
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        download: false,
        filter: false,
        sort: false,
        empty: true,
        width: 100,
        customBodyRender: (dataIndex) => {
          return (
            <Button variant="outlined" color="primary" size="small" onClick={() => {
              history.push(`/subscribers/${dataIndex}`)
            }}>
              Izmijeni
            </Button>
          )
        }
      }
    },
  ]

  const options = {
    filter: false,
    download: true,
    sort: false,
    print: false,
    pagination: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: subscribersCount,
    rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow>
          <TableCell colSpan={rowData.length}>
            Telefon: {rowData[8].phone} <br/>
            Napomena: {rowData[8].note} <br/>
            ŽR: {rowData[8].bankAccounts.join("; ")}
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return (
        <IconButton onClick={() => history.push(`/subscribers/new`)}>
          <Add/>
        </IconButton>
      )
    },
    setRowProps: (rowData) => {
      return {
        className: clsx({
          [classes.active]: !rowData[7] || (rowData[6] && moment(rowData[6], 'DD.MM.YYYY.').isValid() && (moment(rowData[6], 'DD.MM.YYYY.').endOf('day')).isBefore(moment())),
          [classes.activeExpireSoon]: rowData[6] && moment(rowData[6], 'DD.MM.YYYY.').isValid() && (moment(rowData[6], 'DD.MM.YYYY.').endOf('day').subtract(5, 'days')).isBefore(moment()),
        }),
      }
    },
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    onSearchChange: searchText => {
      setSearchTerm(searchText)
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          dispatch(setSubscribersFrom(tableState.page * rowsPerPage))
          break
        case 'search':
          dispatch(setSubscribersFrom(tableState.page * rowsPerPage))
          break
        default:
        //console.log(`action ${action} not handled.`);
      }
    },
  }

  return (
    <>
      <MUIDataTable
        title={
          <Typography variant="h6">
            Lista pretplatnika
          </Typography>
        }
        data={subscribers}
        columns={columns}
        options={options}
        pagination={false}
      />
      <Box display="flex" justifyContent="center" p={1}>
        {['ADMIN'].includes(currentRole) && <Box p={1}>
          <FormControl>
            <InputLabel id="label-seller">Prodavac</InputLabel>
            <Select
              style={{ minWidth: '100%', width: '150px' }}
              labelId="seller"
              name="seller"
              id="seller"
              label="Prodavac"
              value={subscribersFilter.seller || ''}
              onChange={e => {
                dispatch(setSubscribersFilter({
                  ...subscribersFilter,
                  seller: e.target.value
                }))
              }}
            >
              <MenuItem key={0} value={''}>Svi prodavci</MenuItem>
              {sellers.map(s => <MenuItem key={s.id} value={s.id}>{s.firstName} {s.lastName}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}
        {['ADMIN'].includes(currentRole) && <Box p={1}>
          <FormControl>
            <InputLabel id="label-accountant">Računovođa</InputLabel>
            <Select
              style={{ minWidth: '100%', width: '150px' }}
              labelId="accountant"
              name="accountant"
              id="accountant"
              label="Računovođa"
              value={subscribersFilter.accountant || ''}
              onChange={e => {
                dispatch(setSubscribersFilter({
                  ...subscribersFilter,
                  accountant: e.target.value
                }))
              }}
            >
              <MenuItem key={0} value={''}>Sve računovođe</MenuItem>
              {accountants.map(s => <MenuItem key={s.id} value={s.id}>{s.firstName} {s.lastName}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}
        {subscribersCount && subscribersCount / rowsPerPage > 0 ?
          <Box p={1}>
            <FormControl>
              <InputLabel id="label-page">Strana</InputLabel>
              <Select
                style={{ minWidth: '100%', width: '70px' }}
                labelId="page"
                name="page"
                id="page"
                label="Strana"
                value={Math.ceil(subscribersFrom / rowsPerPage)}
                onChange={e => {
                  dispatch(setSubscribersFrom(rowsPerPage * e.target.value))
                }}
              >
                {[...Array(Math.ceil(subscribersCount / rowsPerPage)).keys()].map(i =>
                  <MenuItem key={i} value={i}>{i + 1}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box> : null}
        <Box p={1}>
          <FormControl>
            <InputLabel id="label-per-page">Po strani</InputLabel>
            <Select
              style={{ minWidth: '100%', width: '70px' }}
              labelId="page"
              name="page"
              id="page"
              label="Po strani"
              value={rowsPerPage}
              onChange={e => {
                dispatch(setSubscribersPerPage(e.target.value))
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box p={1} pt={3}>
          <Pagination
            color="primary"
            showFirstButton
            showLastButton
            count={Math.ceil(subscribersCount / rowsPerPage)}
            page={Math.floor(subscribersFrom / rowsPerPage + 1)}
            onChange={handleChangePage}
          />
        </Box>
        <Box p={4}>
          Ukupno: {subscribersCount} / Ukupno enu-a: {enusCount.active}/{enusCount.total}
        </Box>
      </Box>
    </>
  )
};