import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

export default function CancelInvoiceDialog({invoiceToCancel, onClose, disabled, createCorrectiveInvoice}) {
    return <Dialog
      disableBackdropClick
        maxWidth='sm'
        open={!!invoiceToCancel}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle>Da li ste sigurni da želite da stornirate račun?</DialogTitle>
        <DialogContent>
            Račun br. {invoiceToCancel.invoice.invOrdNum}/{invoiceToCancel.invoice.yearOfIssuance}<br/>
            Iznos: {invoiceToCancel.invoice.totPrice ? invoiceToCancel.invoice.totPrice.toFixed(2) : 0}<br/>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Zatvori
            </Button>
            <Button disabled={disabled} onClick={createCorrectiveInvoice} color="secondary" autoFocus>
                Storniraj
            </Button>
        </DialogActions>
    </Dialog>;
}