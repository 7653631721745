import * as React from 'react';
import {
    Box, Button,
    TextField
} from "@material-ui/core";
import {useCallback, useState} from "react";
import {setErrorSnackbar, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch} from "react-redux";
import {useAxios} from "../../utils/hooks";
import MonacoEditor from "react-monaco-editor";
import Grid from "@material-ui/core/Grid";

const options = {
    minimap: {
        enabled: false
    }
};

export default function Template({content, initialTemplate, subscriberId, setContent}) {

    const [template, setTemplate] = useState(initialTemplate);

    const dispatch = useDispatch();
    const axiosInstance = useAxios();

    const postTemplate = useCallback(() => {
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            const templateExist = content && content.templates && content.templates.find(t => t.id === template.id);
            let templatesToSave = templateExist ? content.templates.map(t => t.id === template.id ? template : t) : [...(Array.isArray(content.templates) ? content.templates : []), template];
            axiosInstance.current.post(`/admin/subscriber/${subscriberId}/_settings`, {templates: templatesToSave})
                .then(() => {
                    setContent({
                        ...content,
                        templates: templatesToSave
                    });
                    dispatch(setSuccessSnackbar("Templejt je sačuvan"));
                })
                .catch(err => {
                    console.log(err);
                    dispatch(setErrorSnackbar("Greška"));
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, subscriberId, content, template, setContent]);

    return (
        <Box>
            <Grid container>
                <Grid item sm={12} md={3}>
                    <TextField
                        autoComplete='off'
                        required
                        id="name"
                        name="name"
                        label="Naziv"
                        fullWidth
                        value={template.name}
                        onChange={
                            (event) => {
                                setTemplate({
                                    ...template,
                                    name: event.target.value
                                })
                            }}
                    />
                </Grid>
            </Grid>
            <Grid container>

                <Grid item sm={12} md={6}>
                    <h3>jsonSchema</h3>
                    <MonacoEditor
                        height="300"
                        language="json"
                        value={template.jsonSchema}
                        options={options}
                        onChange={
                            (newValue) => {
                                setTemplate({
                                    ...template,
                                    jsonSchema: newValue
                                })
                            }}
                    />
                </Grid>
                <Grid item sm={12} md={6}>
                    <h3>uiSchema</h3>
                    <MonacoEditor
                        height="300"
                        language="json"
                        value={template.uiSchema}
                        options={options}
                        onChange={
                            (newValue) => {
                                setTemplate({
                                    ...template,
                                    uiSchema: newValue
                                })
                            }}
                    />
                </Grid>
                <Grid item sm={12} md={12}>
                    <h3>template</h3>
                    <MonacoEditor
                        height="300"
                        language="html"
                        value={template.template}
                        options={options}
                        onChange={
                            (newValue) => {
                                setTemplate({
                                    ...template,
                                    template: newValue
                                })
                            }}
                    />
                </Grid>
                <Grid item sm={6} style={{marginTop: 20}}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!template.name}
                        onClick={() => postTemplate()}
                    >
                        Sačuvaj
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
}
