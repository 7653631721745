import { encode } from 'html-entities'

export class InvoiceItemModel {

  constructor (item) {
    this.id = (item && item.id) || ''
    this.code = (item && encode(item.code)) || ''
    this.name = (item && encode(item.name)) || ''
    this.price = (item && item.price) || 0.0
    this.originalPrice = (item && item.price) || 0.0
    this.quantity = (item && Number(item.quantity)) || 0
    this.rebate = (item && item.rebate) || 0.0
    this.rebateReducing = false
    this.unitOfMeasure = (item && encode(item.unitOfMeasure)) || 'kom'
    this.amountOfVAT = (item && item.amountOfVAT) || 0.0
    this.originalVatRate = (item && item.vatRate) || 0
    this.vatRate = (item && item.vatRate) || 0
    this.priceWithVAT = (item && item.priceWithVAT) || 0.0
    this.priceWithQuantWoVAT = (item && item.priceWithQuantWoVAT) || 0.0
    this.unitPriceWoVAT = (item && item.unitPriceWoVAT) || 0.0
    this.unitPriceWithVAT = (item && item.unitPriceWithVAT) || 0.0
    this.exemptFromVAT = (item && item.exemptFromVAT) || "VAT_0"
    this.note = ""
  }

  update (updatedInvoiceItem) {
    Object.assign(this, updatedInvoiceItem)

    if (updatedInvoiceItem.id) {
      this.exemptFromVAT = "VAT_0"
    }

    if (updatedInvoiceItem.price) {
      this.originalPrice = updatedInvoiceItem.price
    }

    if (updatedInvoiceItem.id && updatedInvoiceItem.vatRate) {
      this.originalVatRate = updatedInvoiceItem.vatRate
    }

    if (this.id !== undefined) {
      const [upa, upb, pa, va, pb] = this.amounts();

      this.priceWithVAT = pa
      this.priceWithQuantWoVAT = pb
      this.unitPriceWoVAT = upb
      this.unitPriceWithVAT = upa
      this.amountOfVAT = va
    } else {
      this.priceWithVAT = updatedInvoiceItem.priceWithVAT || this.priceWithVAT
      this.priceWithQuantWoVAT = updatedInvoiceItem.priceWithQuantWoVAT || this.priceWithQuantWoVAT
      this.unitPriceWoVAT = updatedInvoiceItem.unitPriceWoVAT || this.unitPriceWoVAT
      this.unitPriceWithVAT = updatedInvoiceItem.unitPriceWithVAT || this.unitPriceWithVAT
      this.amountOfVAT = updatedInvoiceItem.amountOfVAT || this.amountOfVAT
    }
  }

  amounts () {
    const pom1 = this.roundDecimal(this.roundDecimal(this.price * (1 - this.rebate / 100), 4) * (this.quantity || 1), 2)
    const pom2 = this.roundDecimal((pom1 / (this.quantity || 1)) / (1 + this.vatRate / 100), 6) || 0
    const upa = this.roundDecimal(pom2 * (1 + this.vatRate / 100), 6)
    const pb = this.roundDecimal(pom2 * this.quantity, 6)
    const va = this.roundDecimal(pb * (this.vatRate / 100), 6) || 0
    const pa = this.roundDecimal(pb + va, 2) || 0
    const upb = this.rebate < 100 ? this.roundDecimal(upa / ((1 + this.vatRate / 100) * (1 - this.rebate / 100)), 6) : this.roundDecimal(this.originalPrice / (1 + this.vatRate / 100), 6)
    return [upa, upb, pa, va, pb]
  }

  roundDecimal (val, places) {
    const mod = Math.pow(10.0, places)
    return Math.round(val * mod) / mod
  }

}

