import React from 'react'
import { useSelector } from 'react-redux'
import {
  getCurrentRole, getCurrentStore,
  getCurrentSubscriber,
  getCurrentTcr
} from '../../containers/mainReducer'
import { Box } from '@material-ui/core'
import moment from 'moment-timezone'

const WarningMessage = () => {
  const currentRole = useSelector(getCurrentRole)
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentStore = useSelector(getCurrentStore)

  if (!(currentRole && currentSubscriber && currentTcr)) return null;
  let message = '';

  const dayDifference = Math.round(moment.duration(moment(currentSubscriber.activeDueDate).tz('Europe/Podgorica').diff(moment().tz('Europe/Podgorica'))).asDays())

  // find the newest certificate in the array of subscriber certificates
  const newestCertificate = currentSubscriber && currentSubscriber.certificates.reduce((prev, curr) => {
    return new Date(prev?.details?.validTo) > new Date(curr?.details?.validTo) ? prev : curr;
  }, {});

  if (!currentTcr.active || !currentSubscriber.active ||
    (
      currentSubscriber.activeDueDate &&
      moment(currentSubscriber.activeDueDate).tz('Europe/Podgorica').isBefore(moment().tz('Europe/Podgorica'))
    )
  ) {
    message = 'Vaša licenca je istekla'
  } else if (currentTcr.active && currentSubscriber.active &&
    dayDifference > 0 &&
    dayDifference < 6
  ) {
    message = 'Vaša licenca ističe za ' + dayDifference + (dayDifference === 1 ? ' dan' : ' dana');
  } else if (currentTcr.active && currentSubscriber.active &&
    dayDifference === 0
  ) {
    message = 'Vaša licenca ističe danas';
  } else if (currentStore.allowedInvoiceType === 'CASH') {
    message = `Poslovna jedinica ${currentStore.name} je registrovana samo za gotovinske račune!`;
  } else if (!currentTcr.noncacheInvoiceCert) {
    message = `Nije definisan sertifikat za bezgotovinske račune!`;
  }
  else if (newestCertificate && moment(newestCertificate?.details?.validTo).tz('Europe/Podgorica').isBefore(moment().tz('Europe/Podgorica').add(20, 'days'))) {
    message = `Sertifikat za bezgotovinske račune ističe za ${Math.round(moment.duration(moment(newestCertificate?.details?.validTo).tz('Europe/Podgorica').diff(moment().tz('Europe/Podgorica'))).asDays())} dana! Molimo Vas da se obratite FiscAll prodavcu radi produženja sertifikata!`;
  }
  else if (newestCertificate && moment(newestCertificate?.details?.validTo).tz('Europe/Podgorica').isBefore(moment().tz('Europe/Podgorica'))) {
    message = `Sertifikat za bezgotovinske račune je istekao! Molimo Vas da se obratite FiscAll prodavcu radi produženja sertifikata!`;
  }

  if (['OWNER', 'ACCOUNTANT'].includes(currentRole) && message) {
    return (
      <Box p={2} textAlign={"center"} style={{color: 'red'}}>
        {message}
      </Box>
    )
  } else {
    return null
  }

}

export default WarningMessage
