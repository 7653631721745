import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
const baseURL = process.env.REACT_APP_API_URL;

export const useAxios = () => {
  const axiosInstance = useRef();
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL
    });

    axiosInstance.current.interceptors.request.use(config => {
      config.headers.authorization = 'Bearer ' + keycloak.token;
      return config;
    });

    //TODO implement token refresh
/*    axiosInstance.current.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('error.response.status', error.response.status)
        window.location = '/login';
      }
    });*/

    return () => {
      axiosInstance.current = undefined;
    };
  }, [initialized, keycloak.token]);

  return axiosInstance;
};


export const useTimeout = (callback, delay) => {
  const timeoutRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);
      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);
  return timeoutRef;
};
