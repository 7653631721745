import React from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const ConfirmDialog = ({
  onConfirm,
  closeConfirm,
  loading,
  open,
  msg
}) => {

  return (
    <Dialog
      disableBackdropClick
      open={open}
      maxWidth={'md'}
    >
      <DialogTitle>
        {loading ? "Provjera podataka" : "Potvrdite akciju"}
      </DialogTitle>
      <DialogContent>
        {loading ? (
            <Box display="flex"
                 justifyContent="center"
                 alignItems="center">
              <CircularProgress/>
            </Box>
          ) : msg
        }
      </DialogContent>
      {!loading && <DialogActions>
        <Button
          color="default"
          onClick={() => {
            closeConfirm()
          }}>
          Otkaži
        </Button>
        {onConfirm && <Button
          onClick={() => {
            onConfirm()
          }}
          variant="contained"
          color="primary"
        >
          Potvrdi
        </Button>}
      </DialogActions>}
    </Dialog>
  )
}

export default ConfirmDialog
