import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useEffect, useState } from 'react'
import { Add, BarChart, Delete } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentSubscriber,
  getCurrentTcr,
  getCurrentTcrData, getUser,
  setCurrentTcrData,
  setIsLoading
} from '../mainReducer'
import { InventoryModel } from '../../models/InventoryModel'
import { useAxios } from '../../utils/hooks'
import ItemTurnoverReport from './ItemTurnoverReport'
import { localization, tableIcons } from '../../utils/material-table'
import ProductDialog from './ProductDialog'
import Edit from '@material-ui/icons/Edit'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import moment from 'moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { callAddFont} from "../../utils/Roboto-Regular-normal";
import {downloadCsv} from "../../utils/helpers";

export default function Inventory () {

  const [openItemReport, setOpenItemReport] = React.useState(false)
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentUser = useSelector(getUser)
  const currentTcrData = useSelector(getCurrentTcrData)
  const [currentItemForReport, setCurrentItemForReport] = useState(null)
  const [inventory, setInventory] = useState([])
  const axiosInstance = useAxios()
  const dispatch = useDispatch()
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [editingProduct, setEditingProduct] = useState(null)
  const [productToDelete, setProductToDelete] = useState(null)

  const handleCloseItemReport = () => {
    setOpenItemReport(false)
  }

  useEffect(() => {
    if (currentTcrData && currentTcrData.inventory && currentTcrData.inventory.length > 0) {
      setInventory(currentTcrData.inventory.map(i => {return { ...i }}).filter(i => !i.deleted))
    } else {
      setInventory([])
    }
  }, [currentTcrData])

  useEffect(() => {
    if (editingProduct) {
      setOpenProductDialog(true)
    }
  }, [editingProduct])

  const deleteProduct = async () => {
    const deletedInventory = {
      ...productToDelete,
      deleted: true,
      updatedAt: moment().valueOf(),
      deletedBy: currentUser.name
    }

    delete deletedInventory.tableData;

    setProductToDelete(null)
    dispatch(setIsLoading(true))
    axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_inventory`, deletedInventory)
      .then(r => {
        updateInventory(deletedInventory)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

  const postInventory = (product, isNew, closeDialog) => {
    const inventory = new InventoryModel()
    inventory.update(product)

    if (axiosInstance.current) {
      dispatch(setIsLoading(true))
      if (isNew) {
        const newInventory = {
          ...inventory,
          createdAt: moment().valueOf(),
          creator: currentUser.name
        }
        axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_inventory`, newInventory)
          .then(r => {
            addInventory(newInventory)
            setEditingProduct(null)
            if (closeDialog) setOpenProductDialog(false)
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      } else {
        const updatedInventory = {
          ...inventory,
          updatedAt: moment().valueOf(),
          modifier: currentUser.name
        }
        axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_inventory`, updatedInventory)
          .then(r => {
            updateInventory(updatedInventory)
            setEditingProduct(null)
            setOpenProductDialog(false)
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }
  }

  const addInventory = (item) => {
    dispatch(setCurrentTcrData({
      ...currentTcrData,
      inventory: [item, ...currentTcrData.inventory]
    }))
  }

  const updateInventory = (item) => {
    dispatch(setCurrentTcrData({
      ...currentTcrData,
      inventory: currentTcrData.inventory.map(i => {
        return i.id === item.id ? item : i
      })
    }))
  }

  return (
    <Box style={{ minHeight: 650, marginTop: 10 }}>
      {currentTcrData &&
      <MaterialTable
        title="Artikli"
        icons={tableIcons}
        columns={[
          {
            field: 'sku',
            title: 'Šifra',
            width: 150,
            validate: rowData => !rowData || (!rowData.sku || rowData.sku.trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          {
            field: 'name',
            title: 'Naziv',
            flex: 1,
            validate: rowData => !rowData || (!rowData.name || rowData.name.trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          {
            field: 'price',
            title: 'Cijena',
            width: 150,
            type: 'numeric',
            validate: rowData => !rowData || (!rowData.price || rowData.price.toString().trim().length === 0) ? {
              isValid: false,
              helperText: 'Unesite ispravnu vrijednost'
            } : true
          },
          {
            field: 'taxRate',
            title: 'Porez',
            width: 150,
            initialEditValue: 21,
            lookup: { 0: '0%', 7: '7%', 21: '21%' },
            validate: rowData => !rowData || (!rowData.taxRate || rowData.taxRate.toString().trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          { field: 'unitOfMeasure', title: 'JM', headerName: 'JM', width: 150 }
        ]}
        data={inventory.sort(function (a, b) {
          return a.sku.toString().localeCompare(b.sku.toString(), undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        })}
        options={{
          rowStyle: rowData => {
            if (!rowData.deleted) {
              return {}
            }
            return { backgroundColor: '#ffbaba' }
          },
          pageSize: 10,
          emptyRowsWhenPaging: true,
          pageSizeOptions: [5, 10, 25],
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportCsv: (columns, data) => {
            const headerRow = ["Sifra", "Naziv", "Cijena", "PDV", "jm"];
            const dataRows = data.filter(i => !i.deleted).map( d => [d.sku, d.name, Number(d.price), Number(d.taxRate), d.unitOfMeasure]);
            const delimiter = ',';
            const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n')
            const csvFileName = "Artikli";
            downloadCsv(csvContent, csvFileName);
          },
          exportPdf: (columns, data) => {
            jsPDF.API.events.push(["addFonts", callAddFont]);

            const doc = new jsPDF();
            doc.setFont('Roboto-Regular', 'normal');
            doc.setFontSize(12);

            const pdfData = data.filter(i => !i.deleted).map(rowData =>
              columns.map(columnDef => rowData[columnDef.field]),
            );

            doc.autoTable({
              styles: {
                font: 'Roboto-Regular',
                fontStyle: 'normal',
              },
              head: [columns],
              body: pdfData,
            });

            doc.save(`artikli.pdf`);
          }
        }}
        localization={localization}
        actions={[
          {
            icon: () => <Add/>,
            tooltip: 'Dodaj novi',
            onClick: ((event, rowData) => {
              //setEditingProduct(rowData)
              setOpenProductDialog(true)
            }),
            isFreeAction: true,
          },
          {
            icon: () => <BarChart/>,
            tooltip: 'Izvještaj',
            onClick: ((event, rowData) => {
              setOpenItemReport(true)
              setCurrentItemForReport(rowData)
            }),
            position: 'row'
          },
          {
            icon: () => <Edit/>,
            tooltip: 'Izmijeni',
            onClick: ((event, rowData) => {
              setEditingProduct(rowData)
            }),
            position: 'row'
          },
          {
            icon: () => <Delete/>,
            tooltip: 'Obriši',
            onClick: ((event, rowData) => {
              setProductToDelete(rowData)
            }),
            position: 'row'
          },
        ]}
      />
      }

      {currentItemForReport && currentSubscriber && currentTcr &&
      <Dialog open={openItemReport} onClose={handleCloseItemReport} aria-labelledby="form-dialog-report"
              fullWidth={true} fullScreen
              maxWidth={'md'}>
        <DialogTitle id="form-dialog-report">Izvještaj za proizvod {currentItemForReport.name}</DialogTitle>
        <DialogContent>
          <ItemTurnoverReport itemId={currentItemForReport.id} subscriberId={currentSubscriber.id}
                              tcrId={currentTcr.id}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseItemReport} variant="outlined" color="primary">
            Zatvori
          </Button>

        </DialogActions>
      </Dialog>
      }

      {openProductDialog && <ProductDialog
        openProductDialog={openProductDialog}
        setOpenProductDialog={setOpenProductDialog}
        editingProduct={editingProduct}
        setEditingProduct={setEditingProduct}
        postInventory={postInventory}
        useGoodsReceipt={currentTcr.useGoodsReceipt}
        isIssuerInVAT={currentSubscriber.isIssuerInVAT}
        inventory={inventory}
      />}

      {!!productToDelete &&
      <ConfirmDialog
        open={!!productToDelete}
        onConfirm={deleteProduct}
        msg={`Da li ste sigurni da želite da obrišete arikal ${productToDelete.name} ?`}
        closeConfirm={setProductToDelete}
      />
      }
    </Box>

  )
}