import React, { useEffect, useRef } from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { Add, Delete } from '@material-ui/icons'
import { decode } from 'html-entities'
import { exemptFromVATType } from '../../utils/helpers'
import IconButton from '@material-ui/core/IconButton'

export default function InvoiceItem (
  {
    idx,
    invoiceItemsIds,
    editingItem,
    inventory,
    editInvoiceItem,
    deleteInvoiceItem,
    setItem,
    addItemButtonRef,
    isIssuerInVAT,
    isForeignBuyer,
    priceWoVatForeignInvoice,
    useExemptFromVAT,
    useInvoiceItemNote,
    canChangeInvoiceItemTotal,
    setOpenProductDialog
  }) {

  const originalItem = useRef(editingItem)
  const nameRef = useRef()
  const priceWithoutVATRef = useRef()
  const totalRef = useRef()
  const priceRef = useRef()
  const quantityRef = useRef()
  const rebateRef = useRef()
  const noteRef = useRef()

  useEffect(() => {
    if (editingItem.id) {
      priceWithoutVATRef.current.value = Number(editingItem.unitPriceWoVAT.toFixed(4))
      priceRef.current.value = Number(editingItem.price).toFixed(2)
      quantityRef.current.value = editingItem.quantity
      rebateRef.current.value = editingItem.rebate
      totalRef.current.value = editingItem.priceWithVAT
      originalItem.current = editingItem
    } else {
      priceWithoutVATRef.current.value = 0
      priceRef.current.value = 0
      quantityRef.current.value = 0
      rebateRef.current.value = 0
      totalRef.current.value = 0
    }
  }, [editingItem.id, editingItem.price, editingItem.vatRate, editingItem.unitPriceWoVAT, editingItem.unitPriceWithVAT, editingItem.quantity, editingItem.rebate])

  useEffect(() => {
    invoiceItemsIds && invoiceItemsIds.length > 0 && nameRef && nameRef.current && nameRef.current.focus()
  }, [])

  return (
      <Grid container spacing={1}
            style={{ border: `1px solid ${editingItem.id ? 'grey' : 'red'}`, borderRadius: '5px', marginBottom: '15px', padding: 3, paddingTop: 10 }}>
        <Grid container style={{ width: 'calc(100% - 35px)', padding: 5  }} spacing={1}>
          <Grid item style={{ minWidth: 240, maxWidth: '100%', flexGrow: 1, marginBottom: 5 }}>
            <Autocomplete
              size="small"
              options={inventory.filter(i => invoiceItemsIds && !invoiceItemsIds.includes(i.id))}
              getOptionSelected={(option, value) => option.value === value.value}
              getOptionLabel={option => decode(`${option && option.code ? option.code + ' - ' + option.name : ''}`)}
              style={{ width: '100%', fontSize: 14, padding: 0 }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') priceWithoutVATRef.current.select()
              }}
              filterOptions={(options, state) => {
                return options.filter(o => o.sku.toString().toLowerCase().startsWith(state.inputValue.toLowerCase()) || o.name.toString().toLowerCase().includes(state.inputValue.toLowerCase()))
              }}
              value={editingItem}
              disableClearable={true}
              onChange={(e, value) => {
                const product = value
                if (product) {
                  editingItem.update({
                    id: product.id,
                    code: product.sku,
                    name: product.name,
                    price: isForeignBuyer && priceWoVatForeignInvoice
                      ? Number((product.price / (1 + product.taxRate / 100)).toFixed(4))
                      : product.price,
                    vatRate: isForeignBuyer ? 0 : product.taxRate,
                    unitOfMeasure: product.unitOfMeasure,
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }
              }}
              renderOption={option => <span>{option.sku} - {option.name}</span>}
              renderInput={(params) =>
                <TextField {...params}
                           label="Naziv"
                           inputRef={nameRef}
                           style={{padding: 0}}
                           InputLabelProps={{style: {fontSize: 14}}}
                           inputProps={{
                             ...params.inputProps,
                             style: { fontSize: 14, padding: '2px 5px', paddingRight: 30},
                             endAdornment: (
                               <React.Fragment>
                                 {!editingItem.id ? <IconButton size="small" onClick={() => setOpenProductDialog(true)}>
                                   <Add />
                                 </IconButton> : null}
                                 {params.InputProps.endAdornment}
                               </React.Fragment>
                             ),
                           }}
                           variant="outlined"/>}/>

          </Grid>
          <Grid item style={{ width: 100 }}>
            <TextField
              inputRef={priceWithoutVATRef}
              variant="outlined"
              autoComplete="off"
              id="priceWithoutVAT"
              name="priceWithoutVAT"
              label="Cij. bez PDV"
              fullWidth
              size="small"
              inputProps={{ min: 0, style: { textAlign: 'right', fontSize: 14, padding: '8px 5px' } }}
              defaultValue={0}
              disabled={!editingItem.id}
              onBlur={(e) => {
                if (Number(editingItem.unitPriceWoVAT.toFixed(4)) !== Number(Number(e.target.value).toFixed(4))) {
                  editingItem.update({
                    price: Number((Number(e.target.value) * (1 + editingItem.vatRate / 100)).toFixed(4))
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  priceRef.current.select()
                } else if (e.key === '.') {
                  //do nothing
                } else if (e.which > 31 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              }}
            />
          </Grid>
          <Grid item style={{ width: 100 }}>
            <FormControl style={{ minWidth: '100%', padding:0, fontSize: 12 }} size="small" variant="outlined">
              <InputLabel htmlFor="vatRate">PDV</InputLabel>
              <Select
                inputProps={{ tabIndex: -1}}
                SelectDisplayProps={{ style: {fontSize: 14, padding:8} }}
                style={{ minWidth: '100%', fontSize: 14, padding: 0 }}
                labelId="vatRate"
                name="vatRate"
                id="vatRate"
                value={editingItem.vatRate}
                label="PDV"
                disabled={!editingItem.id}
                onChange={e => {
                  editingItem.update({
                    vatRate: Number(Number(e.target.value).toFixed(4)),
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }
                }
              >
                <MenuItem value={0}>0%</MenuItem>
                {isIssuerInVAT && <MenuItem value={7}>7%</MenuItem>}
                {isIssuerInVAT && <MenuItem value={21}>21%</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          {
            useExemptFromVAT && editingItem && editingItem.id && editingItem.vatRate === 0 && <Grid item xs>
              <FormControl style={{ maxWidth: 160, minWidth: '100%' }} size="small" variant="outlined">
                <InputLabel htmlFor="ex">Razlog oslobadjanja</InputLabel>
                <Select
                  style={{ minWidth: '100%', maxWidth: 200, fontSize: 14, padding: 0, paddingRight: 15 }}
                  inputProps={{ tabIndex: -1}}
                  SelectDisplayProps={{ style: {fontSize: 14, padding:8} }}
                  labelId="exemptFromVAT"
                  name="exemptFromVAT"
                  id="exemptFromVAT"
                  value={editingItem.exemptFromVAT}
                  label="Razlog oslobadjanja"
                  disabled={!editingItem.id}
                  onChange={e => {
                    editingItem.update({
                      exemptFromVAT: e.target.value,
                    })
                    setItem(editingItem, idx)
                    editInvoiceItem(editingItem, idx)
                  }
                  }
                >
                  {Object.keys(exemptFromVATType).map((key, idx) => {
                    return <MenuItem key={idx} value={key}>{exemptFromVATType[key]}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item style={{ width: 100 }}>
            <TextField
              inputRef={priceRef}
              variant="outlined"
              autoComplete="off"
              id="price"
              name="price"
              label="Cij. sa PDV"
              fullWidth
              size="small"
              defaultValue={0}
              disabled={!editingItem.id}
              inputProps={{ min: 0, style: { textAlign: 'right', fontSize: 14, padding: '8px 5px' } }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  quantityRef.current.select()
                } else if (e.key === '.') {
                  //do nothing
                } else if (e.which > 31 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              }}
              onBlur={(e) => {
                if (Number(Number(editingItem.price).toFixed(2)) !== Number(Number(e.target.value).toFixed(2))) {
                  editingItem.update({
                    price: Number(Number(e.target.value).toFixed(4)),
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }
              }}
            />
          </Grid>
          <Grid item style={{ width: 100 }}>
            <TextField
              inputRef={quantityRef}
              variant="outlined"
              autoComplete="off"
              id="quantity"
              name="quantity"
              label="Količina"
              fullWidth
              type="number"
              size="small"
              inputProps={{
                min: 0, style: { textAlign: 'right', fontSize: 14, padding: '8px 5px' }
              }}
              disabled={!editingItem.id}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  rebateRef.current.select()
                } else if (e.key === '.') {
                  //do nothing
                } else if (e.which > 31 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              }}
              error={!(Number(editingItem.quantity) > 0)}
              defaultValue={editingItem.quantity}
              onBlur={(e) => {
                editingItem.update({
                  quantity: Number(e.target.value),
                })
                setItem(editingItem, idx)
                editInvoiceItem(editingItem, idx)
              }
              }
            />
          </Grid>
          <Grid item style={{ width: 90 }}>
            <TextField
              inputRef={rebateRef}
              variant="outlined"
              autoComplete="off"
              id="rebate"
              name="rebate"
              label="Popust"
              fullWidth
              defaultValue={editingItem.rebate}
              type="number"
              size="small"
              disabled={!editingItem.id}
              inputProps={{ min: 0, style: { textAlign: 'right', fontSize: 14, padding: '8px 5px' } }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (canChangeInvoiceItemTotal && totalRef && totalRef.current) {
                    totalRef && totalRef.current && totalRef.current.select()
                  } else if (useInvoiceItemNote && noteRef && noteRef.current) {
                    noteRef && noteRef.current && noteRef.current.focus()
                  } else {
                    addItemButtonRef && addItemButtonRef.current && addItemButtonRef.current.focus()
                  }
                } else if (e.key === '.') {
                  //do nothing
                } else if (e.which > 31 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              }}
              onBlur={(e) => {
                editingItem.update({
                  rebate: isNaN(e.target.value) || Number(e.target.value) > 100 ? 0 : Number(Number(e.target.value).toFixed(2)),
                })
                setItem(editingItem, idx)
                editInvoiceItem(editingItem, idx)
              }}
            />
          </Grid>
          <Grid item style={{ width: 100 }}>
            <TextField
              variant="outlined"
              autoComplete="off"
              id="total"
              name="total"
              label="Ukupno"
              fullWidth
              inputRef={totalRef}
              onBlur={(e) => {
                const rebate = (editingItem.rebate > 0 ? (1 - editingItem.rebate / 100) : 1)
                editingItem.update({
                  price: (editingItem.rebate >= 100 ? editingItem.price : Number(((Number(e.target.value) / rebate) / editingItem.quantity)).toFixed(4))
                })
                setItem(editingItem, idx)
                editInvoiceItem(editingItem, idx)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (useInvoiceItemNote && noteRef && noteRef.current) {
                    noteRef && noteRef.current && noteRef.current.focus()
                  } else {
                    addItemButtonRef && addItemButtonRef.current && addItemButtonRef.current.focus()
                  }
                } else if (e.key === '.') {
                  //do nothing
                } else if (e.which > 31 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              }}
              disabled={!canChangeInvoiceItemTotal || editingItem.quantity <= 0}
              size="small"
              inputProps={{ min: 0, style: { textAlign: 'right', fontSize: 14, padding: '8px 5px'} }}
            />
          </Grid>
        </Grid>
        <Grid item style={{ width: 35 }}>
          <IconButton
            tabIndex={-1}
            size="small"
            onClick={() => {
              deleteInvoiceItem(editingItem)
            }} variant="text" color="default"><Delete/>
          </IconButton>
        </Grid>
        {useInvoiceItemNote && <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                multiline
                rows={1}
                rowsMax={4}
                variant="outlined"
                autoComplete="off"
                id="note"
                name="note"
                label="Opis"
                fullWidth
                size="small"
                inputProps={{ maxLength: 300 }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    addItemButtonRef && addItemButtonRef.current && addItemButtonRef.current.focus()
                  }
                }}
                defaultValue={editingItem.note}
                disabled={!editingItem.id}
                inputRef={noteRef}
                onBlur={(e) => {
                  editingItem.update({
                    note: e.target.value
                  })
                  setItem(editingItem, idx)
                  editInvoiceItem(editingItem, idx)
                }}
              />
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
  )

}