import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default function TcrBasicData (
  {
    tcr,
    setTcr,
    certificates,
    handleChange,
    handleToggleBoolAttr,
    postTcr,
    registerTcr,
    generatePin,
    businUnitCode,
    subscriberTIN
  }) {

  return (
    <Paper elevation={3} style={{ marginTop: 10 }}>
      <Box p={2} style={{ minHeight: 650 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="name"
              label="Naziv"
              fullWidth
              value={tcr.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{
                readOnly: true
              }}
              required
              id="TCR"
              name="TCR"
              label="PU code"
              fullWidth
              value={tcr.TCR}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <InputLabel id="cacheInvoiceCert">Sertifikat za gotovinke račune</InputLabel>
              <Select
                style={{ minWidth: '100%' }}
                labelId="cacheInvoiceCert"
                id="cacheInvoiceCert"
                name="cacheInvoiceCert"
                value={tcr.cacheInvoiceCert && tcr.cacheInvoiceCert.id ? tcr.cacheInvoiceCert.id : ''}
                onChange={(e) => {
                  setTcr({
                    ...tcr,
                    cacheInvoiceCert: certificates.find(c => c.id === e.target.value)
                  })
                }}
              >
                {certificates.map(c => {
                  return <MenuItem key={c.id} value={c.id}>{c.description}</MenuItem>
                })}

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <InputLabel id="noncacheInvoiceCert">Sertifikat za bezgotovinke račune</InputLabel>
              <Select
                style={{ minWidth: '100%' }}
                labelId="noncacheInvoiceCert"
                id="noncacheInvoiceCert"
                name="noncacheInvoiceCert"
                value={tcr.noncacheInvoiceCert && tcr.noncacheInvoiceCert.id ? tcr.noncacheInvoiceCert.id : ''}
                onChange={(e) => {
                  setTcr({
                    ...tcr,
                    noncacheInvoiceCert: certificates.find(c => c.id === e.target.value)
                  })
                }}
              >
                {certificates.map(c => {
                  return <MenuItem key={c.id} value={c.id}>{c.description}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="serialNumber"
              inputProps={{
                readOnly: true
              }}
              label="Serijski broj"
              fullWidth
              value={tcr.serialNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="osVersion"
              inputProps={{
                readOnly: true
              }}
              label="OS verzija"
              fullWidth
              value={tcr.osVersion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="manufacturer"
              inputProps={{
                readOnly: true
              }}
              label="Proizvodjač"
              fullWidth
              value={tcr.manufacturer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="note"
              name="note"
              label="Napomena"
              fullWidth
              value={tcr.note}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox
                color="secondary"
                name="useGoodsReceipt"
                checked={tcr.useGoodsReceipt}
                onChange={handleToggleBoolAttr}
              />}
              label="Koristi zalihe"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox
                color="secondary"
                name="canOperatorUpdateGoodsReceipt"
                checked={tcr.canOperatorUpdateGoodsReceipt}
                onChange={handleToggleBoolAttr}
              />}
              label="Operater može da mijenja prijemnice"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox
                color="secondary"
                name="useCreditNotes"
                checked={tcr.useCreditNotes}
                onChange={handleToggleBoolAttr}
              />}
              label="Koristi Knjižna odobrenja"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox
                color="secondary"
                name="useBarcode"
                checked={tcr.useBarcode}
                onChange={handleToggleBoolAttr}
              />}
              label="Koristi barcode"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox
                color="secondary"
                name="useInventoryCategorization"
                checked={tcr.useInventoryCategorization}
                onChange={handleToggleBoolAttr}
              />}
              label="Koristi kategorizaciju artikala"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={<Checkbox color="secondary" name="active" checked={tcr.active}
                                 onChange={handleToggleBoolAttr}/>}
              label="Aktivan"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>

            {registerTcr && tcr.id && !tcr.TCR && tcr.businUnitCode === businUnitCode && tcr.subscriberTIN === subscriberTIN &&
            <Button onClick={() => {
              registerTcr(tcr)
            }} variant="contained" color="primary">
              Registruj
            </Button>}

            {tcr && tcr.id && tcr.TCR && tcr.businUnitCode === businUnitCode && tcr.subscriberTIN === subscriberTIN &&
            <Button onClick={() => {
              generatePin(tcr)
            }} variant="outlined" color="primary" style={{ marginLeft: 15 }}>
              Generiši pin
            </Button>
            }

            {tcr && tcr.id && (tcr.businUnitCode !== businUnitCode || tcr.subscriberTIN !== subscriberTIN) ?
              <Button onClick={() => {
                postTcr(tcr, true)
              }} variant="contained" color="secondary" style={{ marginLeft: 15 }}>
                Sinhronizuj podatke
              </Button>
              :
              <Button onClick={() => {
                postTcr(tcr, false)
              }} variant="contained" color="primary" style={{ marginLeft: 15 }}>
                Sačuvaj
              </Button>
            }
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}