import React, { useEffect, useState } from 'react'
import { useApi } from '../../api/api'
import { getCurrentTcr, getCurrentSubscriber, setIsLoading } from '../mainReducer'
import { useDispatch, useSelector } from 'react-redux'
import CreditNoteIICRefs from './CreditNoteIICRefs'

const rowsPerPage = 10

const CreditNoteInvoicesForClient = ({ clientId, IICRefs, setIICRefs }) => {

  const currentTcr = useSelector(getCurrentTcr)
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const api = useApi()
  const [invoices, setInvoices] = useState([])
  const dispatch = useDispatch()
  const [invoicesCount, setInvoicesCount] = useState(0)
  const [from, setFrom] = useState(0)
  useEffect(() => {

    const getInvoicesForClient = async () => {
      return await api.getInvoicesForClient(currentSubscriber.id, currentTcr.id, clientId, from, rowsPerPage)
    }

    if (currentSubscriber && currentSubscriber.id && currentTcr && currentTcr.id) {
      dispatch(setIsLoading(true))
      getInvoicesForClient().then(({ data }) => {
        if (data && data.invoices) {
          setInvoices(data.invoices.map(i => {
            return {
              id: i.header ? i.header.uuid : '',
              invNum: i.invoice ? `${i.invoice.invOrdNum}/${i.invoice.yearOfIssuance}` : '',
              totPrice: i.invoice ? i.invoice.totPrice : '',
              issueDateTime: i.invoice ? i.invoice.issueDateTime : '',
              typeOfInv: i.invoice ? i.invoice.typeOfInv : '',
              invoiceType: i.invoice && i.invoice.invoiceType ? i.invoice.invoiceType : 'INVOICE',
              status: i.status,
              externalURL: i.response && i.response.body ? i.response.body.externalURL : (i.response && i.response.error ? i.response.error.externalURL : ''),
              jikr: i.response && i.response.body ? i.response.body.fic : '',
              ikof: i.response && i.response.body ? i.response.body.iic : '',
              ikof2: i.response && i.response.body ? i.response.body.iic : '',
              payMethods: i.invoice ? i.invoice.payMethods : [],
              obj: i.header && i.response && i.invoice && i.response.body ? {
                id: i.header.uuid,
                amount: i.invoice.totPrice,
                iic: i.response.body.iic,
                issueDateTime: i.invoice.issueDateTime,
                invNum: `${i.invoice.invOrdNum}/${i.invoice.yearOfIssuance}`,
                corrective: !!i.header.correctiveInv,
                corrected: !!i.header.corrected,
                invoiceType: i.invoice.invoiceType
              } : null,
            }
          }))
          setInvoicesCount(data.metadata.hits)
        }
      })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [api, dispatch, currentSubscriber, currentTcr.id, clientId, from, setInvoicesCount])

  return (
    <CreditNoteIICRefs IICRefs={IICRefs} invoices={invoices} setIICRefs={setIICRefs} from={from} setFrom={setFrom}
                       invoicesCount={invoicesCount} rowsPerPage={rowsPerPage}/>
  )
}

export default CreditNoteInvoicesForClient