import {forwardRef} from "react";
import {Add} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import * as React from "react";

export const tableIcons = {
    Add: forwardRef((props, ref) => <Add {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

export const localization = {
    toolbar: {
        nRowsSelected: '{0} redova odabrano',
        searchTooltip: 'Pretraga',
        searchPlaceholder: 'Pretraga',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} od {count}',
        labelRowsSelect: 'redova',
        nextAriaLabel: 'Sledeća strana',
        nextTooltip: 'Sledeća strana',
        previousAriaLabel: 'Prethodna strana',
        previousTooltip: 'Prethodna strana',
        lastAriaLabel: 'Poslednja strana',
        lastTooltip: 'Poslednja strana',
        firstAriaLabel: 'Prva strana',
        firstTooltip: 'Prva strana',
    },
    header: {
        actions: 'Akcije'
    },
    body: {
        addTooltip: 'Unos',
        editTooltip: 'Izmjena',
        deleteTooltip: 'Brisanje',
        emptyDataSourceMessage: 'Nema rezultata pretrage',
        filterRow: {
            filterTooltip: 'Filter'
        },
        editRow: {
            deleteText: 'Da li ste sigurni?'
        }
    }
}