import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Container, FormControl,
  Grid, IconButton, InputLabel, MenuItem, Select
} from '@material-ui/core'
import Tile from './Tile'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAdminDashboardData,
  getCurrentRole,
  getDashboardReportData,
  getDashboardReportPeriod, getIsLoading, getSellers, getSubscribersFilter, getUser, setAdminDashboardData,
  setDashboardReportPeriod, setErrorSnackbar,
  setIsLoading, setSellers, setSubscribersFilter,
  setSuccessSnackbar
} from '../mainReducer'
import {
  Business, DoneOutline,
  EuroSymbol,
  Replay,
  Store
} from '@material-ui/icons'
import Sales from './Sales'
import ByStore from './ByStore'
import { getTheme } from '../Setting/settingsReducer'
import { useApi } from '../../api/api'
import MUIDataTable from 'mui-datatables'
import Typography from '@material-ui/core/Typography'
import { Pagination } from '@material-ui/lab'
import moment from 'moment-timezone'
import { useAxios } from '../../utils/hooks'
import {
  calculateDateFrom,
  dashboardInvoiceStatusIcon,
  parseInvoiceTypeChip,
  parsePayMethodsChip, sleep,
  useDashboardInvoicesListStyles,
} from '../../utils/helpers'
import clsx from 'clsx'

const rowsPerPage = 10

const Dashboard = () => {
  const dashboardReportData = useSelector(getDashboardReportData)
  const adminDashboardData = useSelector(getAdminDashboardData)
  const dashboardReportPeriod = useSelector(getDashboardReportPeriod)
  const currentRole = useSelector(getCurrentRole)
  const theme = useSelector(getTheme)
  const dispatch = useDispatch()
  const [currentInvoiceType, setCurrentInvoiceType] = useState()
  const api = useApi()
  const [invoices, setInvoices] = useState([])
  const [invoicesCount, setInvoicesCount] = useState(0)
  const [from, setFrom] = useState(0)
  const [invoiceToRetry, setInvoiceToRetry] = useState(null)
  const axiosInstance = useAxios()
  const isLoading = useSelector(getIsLoading)
  const [counter, setCounter] = useState(0)
  const sellers = useSelector(getSellers)
  const subscribersFilter = useSelector(getSubscribersFilter)
  const user = useSelector(getUser)
  const classes = useDashboardInvoicesListStyles()

  const columns = [
    {
      name: 'invNum',
      label: 'RB',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
      },
    },
    {
      name: 'sellerName',
      label: 'Firma',
      options: {
        customBodyRender: value => <span dangerouslySetInnerHTML={{ __html: value }}/>
      },
    },
    {
      name: 'typeOfInv',
      label: 'Način plaćanja',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => value === 'CASH' ? 'Gotovinski' : 'Bezgotovinski'
      },
    },
    {
      name: 'invoiceType',
      label: 'Tip računa',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parseInvoiceTypeChip(value)
      },
    },
    {
      name: 'payMethods',
      label: ' ',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parsePayMethodsChip(value)
      },
    },
    {
      name: 'issueDateTime',
      label: 'Datum',
      options: {
        customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm'),
        setCellProps: () => ({ style: { width: 100 } }),
      },
    },
    {
      name: 'totPrice',
      label: 'Ukupno',
      options: {
        display: currentRole && ['ADMIN'].includes(currentRole) ? 'true' : 'excluded',
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      },
    },
    {
      name: 'corrected',
      label: 'Kor',
      options: {
        display: 'excluded',
        customBodyRender: (value) =>
          value ? <DoneOutline style={{ color: 'red' }}/> : null
      },
    },
    {
      name: 'platform',
      label: 'P/V',
      options: {
        hint: 'Platforma/Verzija',
        setCellProps: () => ({ style: { width: 80 } }),
      },
    },
    {
      name: 'errorCode',
      label: 'Code',
      options: {
        display: ["SUCCESS", "IN_PROGRESS"].includes(currentInvoiceType) ? 'excluded' : 'true',
        setCellProps: () => ({ style: { width: 60 } }),
      },
    },
    {
      name: 'error',
      label: 'Greška',
      options: {
        display: ["SUCCESS", "IN_PROGRESS"].includes(currentInvoiceType) ? 'excluded' : 'true',
        setCellProps: () => ({ style: { width: 400 } }),
        customBodyRender: (data) => {return <code style={{display: 'inline-block', overflowY: 'scroll', height:60}}>{data}</code>}
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        width: 50,
        customBodyRender: (dataIndex) => {
          const invoice = invoices.find(i => i.fullInvoice.header.uuid === dataIndex)
          if (invoice && invoice.status === 'STALLED') {
            return (
              <IconButton
                color="primary"
                size="small"
                component="span"
                onClick={() => {
                  setInvoiceToRetry(invoice.fullInvoice)
                }}>
                <Replay/>
              </IconButton>
            )
          } else {
            return null
          }
        },
        setCellProps: () => ({ style: { width: 50 } }),
      }
    }
  ]

  const options = {
    filter: false,
    search: false,
    download: false,
    sort: false,
    pagination: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: invoicesCount,
    rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    setRowProps: (rowData) => {
      return {
        className: clsx({
          [classes.CorrectiveRow]: rowData[7]
        }),
        //style: { border: '3px solid blue' },
      }
    },
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setFrom(tableState.page * rowsPerPage)
          break
        case 'search':
          setFrom(tableState.page * rowsPerPage)
          break
        default:
      }
    },
  }

  const retryInvoice = useCallback(async () => {
    if (isLoading || !invoiceToRetry || !invoiceToRetry.invoice || !invoiceToRetry.header || !invoiceToRetry.header.uuid || !invoiceToRetry.header.tcrId || !invoiceToRetry.header.subscriberID) return

    const tcrId = invoiceToRetry.header.tcrId
    const subscriberId = invoiceToRetry.header.subscriberID

    const url = `request/subscriber/${subscriberId}/tcr/${tcrId}/_invoice`
    const urlInvoiceResponse = `response/subscriber/${subscriberId}/tcr/${tcrId}/invoice/${invoiceToRetry.header.uuid}`

    //first check if invoice is already in SUCCESS
    dispatch(setIsLoading(true))
    try {
      const response = await axiosInstance.current.get(urlInvoiceResponse)
      const rData = response.data

      if (rData && rData.status === 'SUCCESS') {
        dispatch(setIsLoading(false))
        dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
        setCounter(counter + 1)
        return
      }

      if (rData && rData.status === 'IN_PROGRESS') {
        dispatch(setIsLoading(false))
        dispatch(setErrorSnackbar('Fiskalizacija ovog računa je u toku'))
        return
      }

      //continue...
      const header = {
        ...invoiceToRetry.header,
        'subseqDelivType': 'NOINTERNET'
      }

      const body = { 'header': header, 'invoice': invoiceToRetry.invoice }

      const validateStatus = (status) => {
        return status >= 200 && status < 303
      }

      if (!!axiosInstance.current) {
        axiosInstance.current.put(url, body, { validateStatus })
          .then(async () => {
            let i = 0
            while (i < 40) {
              await sleep(500).then(async () => {
                try {
                  const response = await axiosInstance.current.get(urlInvoiceResponse)
                  const rData = response.data

                  if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                    i = 40
                    if (rData.status === 'ERROR') {
                      dispatch(setErrorSnackbar(rData.response.error.faultString))
                    } else if (rData.status === 'SUCCESS') {
                      dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                    } else if (rData.status === 'STALLED') {
                      dispatch(setSuccessSnackbar('Račun nije fiskalizovan'))
                    }
                  }
                } catch (e) {
                  console.log('error getting invoice', e)
                }
              })
              i++
            }
          })
          .catch(err => {
            dispatch(setErrorSnackbar('Greška'))
            console.log('err', err)
          })
          .finally(() => {
            setInvoiceToRetry(null)
            sleep(500)
            dispatch(setIsLoading(false))
            setCounter(counter + 1)
          })
      }
    } catch (e) {
      setInvoiceToRetry(null)
      dispatch(setErrorSnackbar('Greška'))
      dispatch(setIsLoading(false))
    }
  }, [api, dispatch, invoiceToRetry])

  useEffect(() => {
    if (invoiceToRetry) {
      retryInvoice(invoiceToRetry)
    }
  }, [invoiceToRetry])

  useEffect(() => {
    if (axiosInstance.current && ['ADMIN', 'SELLER'].includes(currentRole)) {
      dispatch(setIsLoading(true))

      const dateTo = moment().endOf('day').valueOf()
      const dateFrom = calculateDateFrom(dashboardReportPeriod)
      const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
      const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())

      let sellerId

      switch (currentRole) {
        case 'ADMIN':
          sellerId = subscribersFilter.seller
          break
        case 'SELLER':
          sellerId = user.sub
          break
        default:

      }

      const getAdminReport = async () => {
        if (!!axiosInstance.current) {
          const adminDashData = {}
          await axiosInstance.current.get(`/report/subscribers_summary${sellerId ? '?sellerId=' + sellerId : ''}`)
            .then(async ({ data }) => {
              adminDashData.subscribers = data
              await axiosInstance.current.get(`/report/invoice_summary?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${sellerId ? '&sellerId=' + sellerId : ''}`)
                .then(({ data }) => {
                  adminDashData.invoices = data.aggregations && data.aggregations.byStatus && data.aggregations.byStatus.buckets.length > 0 && data.aggregations.byStatus.buckets.reduce((map, obj) => {
                    map[obj.key] = obj.doc_count
                    return map
                  }, {})
                  if (data.aggregations && data.aggregations.totPriceSum && data.aggregations.totPriceSum.value !== undefined) {
                    adminDashData.totPriceSum = Number(data.aggregations.totPriceSum.value)
                  }
                  dispatch(setAdminDashboardData(adminDashData))
                })
            })
        }
      }

      getAdminReport().then(() => {
        api.getInvoices(from, 10, currentInvoiceType, dateFromUrl, dateToUrl, sellerId)
          .then(({ data }) => {
            setInvoices(data.content.map(i => {
              return {
                id: i.header ? i.header.uuid : '',
                invNum: i.invoice ? `${i.invoice.invOrdNum}/${i.invoice.yearOfIssuance}` : '',
                issueDateTime: i.invoice ? i.invoice.issueDateTime : '',
                totPrice: i.invoice ? i.invoice.totPrice : '',
                sellerName: i.invoice && i.invoice.seller ? i.invoice.seller.name : '',
                typeOfInv: i.invoice ? i.invoice.typeOfInv : '',
                invoiceType: i.invoice && i.invoice.invoiceType ? i.invoice.invoiceType : 'INVOICE',
                payMethods: i.invoice ? i.invoice.payMethods : [],
                errorCode: i.response && i.response.error ? i.response.error.code : '',
                error: i.response && i.response.error ? i.response.error.faultString : '',
                corrected: i.header ? i.header.corrected || i.header.correctiveInv : false,
                status: i.status,
                platform: i.header && i.header.appVersion && i.header.platform ? `${i.header.platform}/${i.header.appVersion}` : '',
                fullInvoice: i
              }
            }))
            setInvoicesCount(data.header.totalItems)
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      })
    }

  }, [api, axiosInstance, from, currentInvoiceType, dashboardReportPeriod, counter, currentRole, subscribersFilter.seller, user])

  useEffect(() => {
    if (currentRole && ['ADMIN'].includes(currentRole) && sellers.length === 0) {
      api.getSellers()
        .then(({ data }) => {
          dispatch(setSellers(data))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [api, sellers, dispatch, currentRole])

  return (

    <Container maxWidth={false}>
      {dashboardReportData ?
        <Grid
          container
          spacing={3}
        >
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Tile text={'POPUST'} value={dashboardReportData.totalDiscount} color={theme.palette.primary.main}
                  icon={<EuroSymbol/>}/>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Tile text="UKUPNO BEZ PDV-A" value={dashboardReportData.totalPriceWithoutVAT}
                  color={theme.palette.primary.main}
                  icon={<EuroSymbol/>}/>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Tile text="UKUPNO POREZA" value={dashboardReportData.totalAmountOfVAT} color={theme.palette.primary.main}
                  icon={<EuroSymbol/>}/>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Tile text="UKUPNO" value={dashboardReportData.totalPriceWithVAT} color={theme.palette.primary.main}
                  icon={<EuroSymbol/>}/>
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales/>
          </Grid>
          {dashboardReportData && dashboardReportData.sumsByStores && dashboardReportData.sumsByStores.length > 0 &&
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <ByStore sumsByStores={dashboardReportData.sumsByStores}/>
          </Grid>}
        </Grid> : <div/>}

      {['ADMIN', 'SELLER'].includes(currentRole) && adminDashboardData ?
        <>
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Tile text={'Aktivne firme'} value={adminDashboardData.subscribers.subscriberCount}
                    color={theme.palette.primary.main} doNotRound
                    icon={<Business/>}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Tile text={'Aktivnih ENU'} value={adminDashboardData.subscribers.tcrCount}
                    color={theme.palette.primary.main} doNotRound
                    icon={<Store/>}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Tile text={'Iznos'} value={adminDashboardData.totPriceSum}
                    color={theme.palette.primary.main}
                    icon={<EuroSymbol/>}
              />
            </Grid>
          </Grid>
          <br/>
          <FormControl style={{ width: 200 }}>
            <InputLabel id="period">Period</InputLabel>
            <Select
              style={{ minWidth: '100%' }}
              labelId="period"
              name="period"
              id="period"
              value={dashboardReportPeriod}
              onChange={event => {
                dispatch(setDashboardReportPeriod(event.target.value))
              }}
            >
              <MenuItem value={0}>Danas</MenuItem>
              <MenuItem value={5}>Poslednjih 7 dana</MenuItem>
              <MenuItem value={6}>Poslednjih 30 dana</MenuItem>
              <MenuItem value={4}>Od početka godine</MenuItem>
              <MenuItem value={7}>Od pamtivijeka</MenuItem>
            </Select>
          </FormControl>
          {['ADMIN'].includes(currentRole) &&
          <FormControl style={{ width: 200, marginLeft: 10}}>
            <InputLabel id="label-seller">Prodavac</InputLabel>
            <Select
              style={{ minWidth: '100%', width: '150px' }}
              labelId="seller"
              name="seller"
              id="seller"
              label="Prodavac"
              value={subscribersFilter.seller || ''}
              onChange={e => {
                dispatch(setSubscribersFilter({
                  ...subscribersFilter,
                  seller: e.target.value
                }))
              }}
            >
              <MenuItem key={0} value={''}>Svi prodavci</MenuItem>
              {sellers.map(s => <MenuItem key={s.id} value={s.id}>{s.firstName} {s.lastName}</MenuItem>)}
            </Select>
          </FormControl>
          }
          <br/>
          <br/>
          <Grid
            container
            spacing={3}
          >

            {Object.keys(adminDashboardData.invoices).map(o => {
              return <Grid key={o} item lg={3} sm={6} xl={3} xs={12}>
                <Tile text={o} value={adminDashboardData.invoices[o]}
                      color={theme.palette.primary.main} decimalPlaces={0}
                      onClick={() => {
                        setCurrentInvoiceType(o)
                        setFrom(0)
                      }
                      }
                      selected={o === currentInvoiceType}
                      icon={dashboardInvoiceStatusIcon(o)}/>
              </Grid>
            })
            }
          </Grid>

          <br/>
          <MUIDataTable
            title={
              <Typography variant="h6">
                Lista faktura
              </Typography>
            }
            data={invoices}
            columns={columns}
            options={options}
          />
          <Box display="flex" justifyContent="center" p={1}>
            <Box p={1}>
              <Pagination
                color="primary"
                showFirstButton
                showLastButton
                count={Math.ceil(invoicesCount / rowsPerPage)}
                page={Math.floor(from / rowsPerPage + 1)}
                onChange={(e, v) => {
                  setFrom(rowsPerPage * (v - 1))
                }}
              />
            </Box>
            <Box p={2}>
              Ukupno: {invoicesCount}
            </Box>
          </Box>
        </> : <div/>}
    </Container>
  )
}

export default Dashboard