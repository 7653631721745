import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentTcrData, setIsLoading } from '../mainReducer'
import Grid from '@material-ui/core/Grid'
import MUIDataTable from 'mui-datatables'
import { useApi } from '../../api/api'

export default function ItemsStockReport ({ subscriberId, tcrId }) {
  const columns = [
    {
      name: 'sku',
      label: 'Šifra',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return obj1.data.localeCompare(obj2.data, undefined, {
              numeric: true,
              sensitivity: 'base'
            }) * (order === 'asc' ? 1 : -1)
          }
        }
      }
    },
    {
      name: 'name',
      label: 'Naziv',
      options: {}
    },
    {
      name: 'totalQuantity',
      label: 'Količina',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseInt(obj1.data, 10)
            let val2 = parseInt(obj2.data, 10)
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          }
        }
      }
    }
  ]

  const options = {
    filter: false,
    search: true,
    download: false,
    sort: true,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: false,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    pagination: false,
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },

  }

  const dispatch = useDispatch()
  const [reportData, setReportData] = useState()
  const currentTcrData = useSelector(getCurrentTcrData)
  const api = useApi()

  const inventoryHashMap = (currentTcrData && currentTcrData.inventory && currentTcrData.inventory.length > 0 ? currentTcrData.inventory : []).reduce(function (map, obj) {
    map[obj.id] = obj
    return map
  }, {})

  useEffect(() => {
    dispatch(setIsLoading(true))
    setReportData(null)
    api.getInvoiceItemsStock(subscriberId, tcrId)
      .then(({ data }) => {

        const invoiceItemsStockReportHashMap = Object.keys(data).reduce(function (map, key) {
          if (inventoryHashMap[key]) {
            map[key] = {
              name: inventoryHashMap[key] && inventoryHashMap[key].name,
              sku: inventoryHashMap[key] && inventoryHashMap[key].sku,
              totalQuantity: data[key]
            }
          }
          return map
        }, {})

        api.getGoodsReceiptItemsStock(subscriberId, tcrId)
          .then(({ data }) => {
            const goodsReceiptItemsStockReportHashMap = Object.keys(data).reduce(function (map, key) {
              if (inventoryHashMap[key]) {
                map[key] = {
                  name: inventoryHashMap[key] && inventoryHashMap[key].name,
                  sku: inventoryHashMap[key] && inventoryHashMap[key].sku,
                  totalQuantity: data[key]
                }
              }
              return map
            }, {})

            const table = [];
            currentTcrData.inventory.forEach(i => {
              if (invoiceItemsStockReportHashMap[i.id] || goodsReceiptItemsStockReportHashMap[i.id]) {
                table.push({
                  id: i.id,
                  sku: i.sku,
                  name: i.name,
                  totalQuantity:
                    ((goodsReceiptItemsStockReportHashMap[i.id] ? goodsReceiptItemsStockReportHashMap[i.id].totalQuantity : 0)
                    -
                    (invoiceItemsStockReportHashMap[i.id] ? invoiceItemsStockReportHashMap[i.id].totalQuantity : 0)).toFixed(3)
                })
              }
            })
            setReportData(table)
          })
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }, [api, dispatch, subscriberId, tcrId])

  return (
    <Grid container>
      {reportData &&
      <Grid item xs={12} style={{ minHeight: 450, marginBottom: 15 }}>
        <MUIDataTable
          title=""
          data={reportData.sort(function (a, b) {
            return a.sku.toString().localeCompare(b.sku, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          })}
          columns={[...columns]}
          options={options}
        />
      </Grid>}
    </Grid>
  )
}