export class SubscriberModel {

  constructor () {
    this.id = ''
    this.active = true
    this.isIssuerInVAT = true
    this.address = ''
    this.certificates = []
    this.country = 'MNE'
    this.name = ''
    this.note = ''
    this.phone = ''
    this.responsiblePerson = ''
    this.stores = []
    this.taxIdentifier = ''
    this.taxIdentifierType = 'TIN'
    this.vatNumber = ''
    this.town = ''
    this.users = []
    this.bankAccounts = []
    this.owner = null
    this.accountant = null
    this.seller = null
    this.activeDueDate = null
  }

  update (updatedSubscriber) {
    this.id = updatedSubscriber.id || this.id
    this.active = updatedSubscriber.active !== undefined ? updatedSubscriber.active : this.active
    this.isIssuerInVAT = updatedSubscriber.isIssuerInVAT !== undefined ? updatedSubscriber.isIssuerInVAT : this.isIssuerInVAT
    this.address = updatedSubscriber.address || this.address
    this.certificates = updatedSubscriber.certificates || this.certificates
    this.country = updatedSubscriber.country || this.country
    this.name = updatedSubscriber.name || this.name
    this.note = updatedSubscriber.note || this.note
    this.phone = updatedSubscriber.phone || this.phone
    this.responsiblePerson = updatedSubscriber.responsiblePerson || this.responsiblePerson
    this.stores = updatedSubscriber.stores || this.stores
    this.taxIdentifier = updatedSubscriber.taxIdentifier || this.taxIdentifier
    this.taxIdentifierType = updatedSubscriber.taxIdentifierType || this.taxIdentifierType
    this.town = updatedSubscriber.town || this.town
    this.vatNumber = updatedSubscriber.vatNumber || this.vatNumber
    this.users = updatedSubscriber.users || this.users
    this.bankAccounts = updatedSubscriber.bankAccounts || this.bankAccounts
    this.owner = updatedSubscriber.owner || this.owner
    this.accountant = updatedSubscriber.accountant || this.accountant
    this.seller = updatedSubscriber.seller || this.seller
    this.activeDueDate = updatedSubscriber.activeDueDate || this.activeDueDate
  }

  toJson () {
    const subscriber = { ...this }
    if (!subscriber.owner) subscriber.owner = { id: '', name: '  ', email: '  ' }
    if (!subscriber.accountant) subscriber.accountant = { id: '', name: '  ', email: '  ' }
    if (!subscriber.seller) subscriber.seller = { id: '', name: '  ', email: '  ' }
    if (!subscriber.activeDueDate) delete subscriber.activeDueDate
    return subscriber
  }

}

