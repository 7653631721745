import React from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment-timezone'
import { Add} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { parseInvoiceTypeChip } from '../../utils/helpers'

const CreditNoteIICRefs = ({ IICRefs, client, setShowModalForIICRefs }) => {

  const options = {
    filter: false,
    search: false,
    download: false,
    sort: false,
    pagination: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: IICRefs.length,
    rowsPerPage: IICRefs.length || 1,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: false,
    customToolbar: () => {
      return (
        client ? <IconButton onClick={() => setShowModalForIICRefs(true)}>
          <Add/>
        </IconButton> : null
      )
    },
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
  }

  const columns = [
    {
      name: 'invNum',
      label: 'RB',
      options: {
        setCellProps: () => ({ style: { width: 100 } })
      },
    },
    {
      name: 'invoiceType',
      label: 'Tip računa',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parseInvoiceTypeChip(value)
      },
    },
    {
      name: 'iic',
      label: 'IKOF',
      options: {
        setCellProps: () => ({ style: { } })
      },
    },
    {
      name: 'issueDateTime',
      label: 'Datum',
      options: {
        setCellProps: () => ({ style: { width: 140 } }),
        customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      name: 'amount',
      label: 'Ukupno',
      options: {
        setCellProps: () => ({ style: { width: 90, textAlign: 'right' } }),
        customBodyRender: value => value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }
    }
  ]

  return (
    <>
      <MUIDataTable
        title={
            "Računi na koji se umanjenje prometa odnosi "
        }
        data={IICRefs}
        columns={columns}
        options={options}
      />
    </>
  )
}

export default CreditNoteIICRefs