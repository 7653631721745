import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxios } from '../../utils/hooks'
import { useHistory } from 'react-router'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment, InputLabel, MenuItem,
  Paper, Select, useTheme
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSuccessSnackbar,
  setInvoiceToPrint,
  setErrorSnackbar,
  setIsLoading,
  getCurrentTcrData,
  getCurrentTcr,
  getCurrentStore,
  getCurrentSubscriber,
  setCurrentTCRInventory,
  setCurrentTCRClients,
  getInvoice,
  setInvoice,
  setInvoiceTemplate,
  getInvoiceTemplate,
  getInvoiceTemplateFormData,
  setInvoiceTemplateFormData,
  resetInvoice
} from '../mainReducer'
import InvoiceItem from './InvoiceItem'
import moment from 'moment-timezone'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import MomentUtils from '@date-io/moment'
import 'moment/locale/me'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { decode } from 'html-entities'
import { InvoiceItemModel } from '../../models/InvoiceItemModel'
import Form from '@rjsf/material-ui'
import JsxParser from 'react-jsx-parser'
import { Add, MoreVert, PlusOne } from '@material-ui/icons'
import NonCacheInvoice from './NonCacheInvoice'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ProductDialog from '../TcrData/ProductDialog'
import { InventoryModel } from '../../models/InventoryModel'
import ClientDialog from '../TcrData/ClientDialog'
import { v4 as uuidv4 } from 'uuid'
import IconButton from '@material-ui/core/IconButton'
import packageJson from '../../../package.json'
import SupplyDate from './SupplyDate'
import { readString } from 'react-papaparse'
import { roundDecimal, sleep } from '../../utils/helpers'

const collator = new Intl.Collator([], { numeric: true })

export default function NewInvoice () {

  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentStore = useSelector(getCurrentStore)
  const currentTcrData = useSelector(getCurrentTcrData)
  const invoice = useSelector(getInvoice)
  const [counter, setCounter] = useState(0)
  const operatorRef = useRef()
  const addDueDateRef = useRef()
  const noteRef = useRef()
  const addItemButtonRef = useRef()
  const importText = useRef()
  const [importTextValue, setImportTextValue]= useState("")
  const axiosInstance = useAxios()
  const [templates, setTemplates] = useState([])
  const [settings, setSettings] = useState({})
  const invoiceTemplate = useSelector(getInvoiceTemplate)
  const invoiceTemplateFormData = useSelector(getInvoiceTemplateFormData)
  const [focusFirstInvoiceItem, setFocusFirstInvoiceItem] = useState(false)
  const [addDaysToDueDate, setAddDaysToDueDate] = useState(localStorage.getItem('addDaysToDueDate') || 0)
  const [nextInvoiceNumber, setNextInvoiceNumber] = useState()
  const [showImportModal, setShowImportModal] = useState(false)
  const [invoiceType, setInvoiceType] = useState('INVOICE')
  const [isForeignBuyer, setIsForeignBuyer] = useState(false)
  const [priceWoVatForeignInvoice, setPriceWoVatForeignInvoice] = useState(localStorage.getItem('fiscall-foreign-price-wo-vat') === 'true')
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [openClientDialog, setOpenClientDialog] = useState(false)

  const useStyles = makeStyles({
    root: {
      // minWidth: 275,
    },
    card: {
      padding: "8px 1px 8px 5px !important"
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 12,
    },
    clientValidation: {
      borderWidth: "1px",
      borderColor: `${invoice && invoice.client ? 'grey' : 'red'} !important`
    },
    operatorValidation: {
      borderWidth: "1px",
      borderColor: `${invoice && invoice.operatorCode ? 'grey' : 'red'} !important`
    }
  })

  const classes = useStyles()

  useEffect(() => {
    updateInvoice({
      ...invoice,
      operatorCode: invoice.operatorCode || (currentTcr.users && currentTcr.users.length === 1 ? currentTcr.users[0].operatorCode : ''),
      dueDate: moment().add(Number(addDaysToDueDate), 'days').tz('Europe/Podgorica').format()
    })
  }, [currentTcr.id])

  useEffect(() => {
    const getTemplates = () => {
      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.get(`/admin/subscriber/${currentSubscriber.id}/_settings`)
          .then((response) => {
            if (response) {
              const { data } = response
              if (data && data.content) {
                setTemplates(data.content.templates)
                setSettings(data.content)
              }
            }
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

    if (currentSubscriber.id) getTemplates()
  }, [axiosInstance, dispatch, currentSubscriber.id])

  const updateInvoice = (invoiceAttribute) => {
    if (invoiceAttribute && invoiceAttribute.client) {
      if (invoiceAttribute.client.country !== 'MNE') {
        invoice.items.forEach(i => i.update(
          {
            vatRate: 0,
            price: invoiceAttribute.client.country !== 'MNE' && priceWoVatForeignInvoice
              ? Number((i.price / (1 + i.originalVatRate / 100)).toFixed(2))
              : i.price,
          }
        ))
      }
    }
    invoice.update({
      ...invoiceAttribute
    })
    setCounter(counter + 1)
    dispatch(setInvoice(invoice))
  }

  const editInvoiceItem = (item, idx) => {
    const updatedInvoiceItems = invoice.items.map((i, index) => {
      if (index === idx) {
        return item
      } else {
        return i
      }
    })

    updateInvoice({ items: updatedInvoiceItems })

  }

  const addInvoiceItem = (item) => {
    if (item) {
      const existingInvoiceItem = invoice.items.find(i => i.id === item.id)
      if (!existingInvoiceItem) {
        const newInvoiceItem = new InvoiceItemModel()
        newInvoiceItem.update({
          rebate: Number(Number(invoice.discountPercentage.toFixed(2)))
        })
        const updatedInvoiceItems = [...invoice.items, newInvoiceItem]
        updateInvoice({ items: updatedInvoiceItems })
      }
    } else {
      const newInvoiceItem = new InvoiceItemModel()
      newInvoiceItem.update({
        rebate: Number(Number(invoice.discountPercentage.toFixed(2)))
      })
      updateInvoice({ items: [...invoice.items, newInvoiceItem] })
    }
  }

  const deleteInvoiceItem = (item) => {
    const updatedInvoiceItems = invoice.items.filter(i => i.id !== item.id)
    updateInvoice({ items: updatedInvoiceItems })
  }

  const getInvoicesCount = useCallback(async () => {
    let invNumber = 0
    //important status=SUCCESS,STALLED
    const url = `report/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/_max?status=SUCCESS,STALLED`
    if (!!axiosInstance.current) {
      dispatch(setIsLoading(true))
      await axiosInstance.current.get(url)
        .then(({ data }) => {
          if (data && data.maxOrdNum >= 0) {
            invNumber = Number(data.maxOrdNum) + 1
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
    return invNumber
  }, [axiosInstance, currentTcr.id, currentTcr.subscriberId, dispatch])

  const postInventory = useCallback((product) => {
    const inv = new InventoryModel()
    inv.update(product)
    if (axiosInstance.current) {
      dispatch(setIsLoading(true))
      axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_inventory`, inv.toJson())
        .then(() => {
          dispatch(setCurrentTCRInventory([...currentTcrData.inventory, inv.toJson()]))
        })
        .finally(() => {
          setOpenProductDialog(false)
          dispatch(setIsLoading(false))
        })
    }
  }, [dispatch, axiosInstance, currentTcr, currentSubscriber, currentTcrData, setOpenProductDialog])

  const postClient = useCallback((client) => {
    if (axiosInstance.current) {
      dispatch(setIsLoading(true))
      delete client.tableData
      client = {
        ...client,
        id: uuidv4(),
        createdAt: moment().valueOf()
      }
      axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_clients`, client)
        .then(() => {
          dispatch(setCurrentTCRClients([...currentTcrData.clients, client]))
        })
        .finally(() => {
          setOpenClientDialog(false)
          dispatch(setIsLoading(false))
        })
    }
  }, [dispatch, axiosInstance, currentTcr, currentSubscriber, currentTcrData, setOpenClientDialog])

  const postInvoice = useCallback(async () => {
    const currentTime = moment().tz('Europe/Podgorica').format()
    const invOrdNum = await getInvoicesCount()
    if (!(invOrdNum > 0)) {
      dispatch(setErrorSnackbar('Greška, provjerite vezu sa internetom i pokušajte ponovo'))
    } else {
      const url = `request/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/_invoice`
      invoice.update({
        invOrdNum,
        yearOfIssuance: (new Date()).getFullYear().toString(),
        businUnitCode: currentTcr.businUnitCode,
        isIssuerInVAT: currentSubscriber.isIssuerInVAT,
        softCode: process.env.REACT_APP_SOFT_CODE,
        issueDateTime: currentTime,
        tcrCode: currentTcr.TCR,
        payMethods: [{ amt: invoice.totPrice, type: 'ACCOUNT' }],
        items: invoice.items.filter(i => !!i.id),
        invoiceType: invoiceType,
        seller: {
          'idNum': currentSubscriber.taxIdentifier,
          'idType': currentSubscriber.taxIdentifierType,
          'name': currentSubscriber.name,
          'address': currentSubscriber.address,
          'vatNumber': currentSubscriber.vatNumber,
          'town': currentSubscriber.town,
          'country': currentSubscriber.country,
          'isIssuerInVAT': currentSubscriber.isIssuerInVAT,
          'active': currentSubscriber.active,
          'bankAccounts': currentSubscriber.bankAccounts
        },
        note: noteRef && noteRef.current ? noteRef.current.innerHTML : ''
      })

      const header = {
        'sendDateTime': currentTime,
        'subscriberID': currentTcr.subscriberId,
        'tcrId': currentTcr.id,
        'certificateID': invoice.typeOfInv === 'CASH' ? currentTcr.cacheInvoiceCert.id : currentTcr.noncacheInvoiceCert.id,
        'platform': 'web',
        'appVersion': packageJson.version
      }

      const body = { 'header': header, 'invoice': invoice.toJson() }

      const validateStatus = (status) => {
        return status >= 200 && status < 303
      }

      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.post(url, body, { validateStatus })
          .then(async ({ data }) => {
            const invoiceId = data.id
            const urlInvoiceResponse = `response/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/${invoiceId}`
            let i = 0
            while (i < 40) {
              await sleep(500).then(async () => {
                try {
                  const response = await axiosInstance.current.get(urlInvoiceResponse)
                  const rData = response.data

                  if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                    i = 40
                    if (rData.status === 'ERROR') {
                      dispatch(setErrorSnackbar(rData.response.error.faultString))
                    } else if (rData.status === 'SUCCESS') {
                      dispatch(setInvoiceToPrint({ invoiceToPrint: invoiceId }))
                      dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                      dispatch(resetInvoice())
                      history.push(`/invoices`)
                    } else if (rData.status === 'STALLED') {
                      dispatch(setInvoiceToPrint({ invoiceToPrint: invoiceId }))
                      dispatch(setSuccessSnackbar('Račun je sačuvan, ali nije fiskalizovan'))
                      dispatch(resetInvoice())
                      history.push(`/invoices`)
                    }
                  }
                } catch (e) {
                  console.log('error getting invoice', e)
                }
              })
              i++
            }
          })
          .catch(err => {
            let errMessage = ''
            if (err && err.response && err.response.data) {
              console.log('err', err.response.data)
              if (err.response.data.msg) {
                errMessage = err.response.data.msg
              } else {
                errMessage = JSON.stringify(err.response.data)
              }
            } else {
              console.log('err', err)
            }
            dispatch(setErrorSnackbar('Greška: ' + errMessage))
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

  }, [axiosInstance, dispatch, history, currentTcr, invoice, currentSubscriber, getInvoicesCount, invoiceType])

  return (
    <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid container>
        <Grid item xs={12} style={{marginBottom: 20}}>
          <Box p={2}>
            <Grid container spacing={1}>
              {/*<Grid item style={{ width: '40px' }}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setShowImportModal(true)
                  }}
                >
                  <MoreVert/>
                </IconButton>

              </Grid>*/}
              <Grid item style={{ width: '220px' }}>
                <FormControl style={{ width: '100%' }} variant="outlined" size="small">
                  <InputLabel id="taxIdentifierType">Tip</InputLabel>
                  <Select
                    style={{ minWidth: '100%' }}
                    labelId="invoiceType"
                    name="invoiceType"
                    id="invoiceType"
                    value={invoiceType}
                    label="Tip"
                    onChange={(event) => {
                      setInvoiceType(event.target.value)
                    }}
                  >
                    <MenuItem value={'INVOICE'}>{isForeignBuyer ? 'INO faktura' : 'Faktura/Otpremnica'}</MenuItem>
                    <MenuItem value={'ADVANCE'}>Avansni račun</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {currentTcrData && <Grid item style={{ minWidth: '247px', flexGrow: 1 }}>
                <Autocomplete
                  id="combo-box-owner"
                  key={'invoice-client'}
                  size="small"
                  value={invoice.client}
                  options={currentTcrData && currentTcrData.clients ? currentTcrData.clients.filter(c => !c.deleted).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => decode(option.name)}
                  renderOption={option => <span
                    style={{ color: option.country !== 'MNE' ? theme.palette.primary.main : theme.palette.text }}>{option.name}</span>}
                  style={{ width: '100%' }}
                  disableClearable
                  onChange={(event, value) => {
                    if (value) {
                      updateInvoice({ client: value })
                    } else {
                      updateInvoice({ client: null })
                    }
                    setIsForeignBuyer(!!value && value.country !== 'MNE')
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && invoice && invoice.client) operatorRef.current.focus()
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                               label="Partner"
                               autoFocus
                               InputProps={{
                                 ...params.InputProps,
                                 classes: {
                                   notchedOutline: classes.clientValidation
                                 },
                                 endAdornment: (
                                   <React.Fragment>
                                     {!invoice.client ? <IconButton size="small" style={{ height: 16 }}
                                                                    onClick={() => setOpenClientDialog(true)}>
                                       <Add/>
                                     </IconButton> : null}
                                     {params.InputProps.endAdornment}
                                   </React.Fragment>
                                 ),
                               }}
                               variant="outlined" required/>}/>
              </Grid>}
              {isForeignBuyer &&
              <Grid item style={{ width: '220px' }}>
                <FormControlLabel
                  control={<Checkbox
                    color="secondary"
                    name="priceWoVAT"
                    checked={priceWoVatForeignInvoice}
                    onChange={() => {
                      localStorage.setItem('fiscall-foreign-price-wo-vat', (!priceWoVatForeignInvoice).toString())
                      setPriceWoVatForeignInvoice(!priceWoVatForeignInvoice)
                    }}
                  />}
                  label="Zadrži iznose bez PDV"
                />
              </Grid>}
              <Grid item style={{ minWidth: 247, flexGrow: 1, maxWidth: 400 }}>
                {currentTcr && currentTcr.users &&
                <Autocomplete
                  id="combo-box-operator"
                  size="small"
                  style={{ width: '100%' }}
                  disableClearable
                  options={currentTcr && currentTcr.users ? currentTcr.users.filter(u => !u.deleted) : []}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  value={currentTcr.users ? (invoice.operatorCode ? currentTcr.users.find(u => u.operatorCode === invoice.operatorCode) : (currentTcr.users.length === 1 ? currentTcr.users[0] : null)) : null}
                  onChange={(event, value) => {
                    if (value) {
                      updateInvoice({ operatorCode: value.operatorCode })
                    } else {
                      updateInvoice({ operatorCode: '' })
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && invoice && invoice.operatorCode) {
                      addDueDateRef && addDueDateRef.current && addDueDateRef.current.select()
                    }
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                               label="Operater"
                               inputRef={operatorRef}
                               required
                               InputProps={{
                                 ...params.InputProps,
                                 classes: {
                                   notchedOutline: classes.operatorValidation
                                 }
                               }}
                               variant="outlined"/>}/>

                }
              </Grid>
              <Grid item style={{ width: '282px' }}>
                <Grid item style={{ width: 280 }}>
                  <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
                    <KeyboardDatePicker
                      tabIndex={-1}
                      clearable
                      disableToolbar
                      minDate={new Date()}
                      disablePast
                      size="small"
                      style={{ width: '100%', minWidth: 165, maxWidth: 165 }}
                      inputVariant="outlined"
                      margin="none"
                      id="date-picker-dialog-dueDate"
                      label="Valuta"
                      format="DD.MM.yyyy."
                      value={invoice.dueDate ? moment(invoice.dueDate) : null}
                      inputValue={invoice.dueDate ? moment(invoice.dueDate).format('DD.MM.yyyy.') : null}
                      onChange={(value) => {
                        updateInvoice({
                          dueDate: value ? moment(value).add(7, 'hour').tz('Europe/Podgorica').format() : null
                        })
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    inputRef={addDueDateRef}
                    variant="outlined"
                    autoComplete="off"
                    id="addDaysToDueDate"
                    name="addDaysToDueDate"
                    size="small"
                    type="number"
                    style={{ maxWidth: 110 }}
                    value={addDaysToDueDate}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">dana</InputAdornment>,
                    }}
                    onChange={(event) => {
                      if (!isNaN(Number(event.target.value))) {
                        setAddDaysToDueDate(event.target.value)
                        updateInvoice({
                          dueDate: moment().add(Number(event.target.value), 'days').add(3, 'hour').tz('Europe/Podgorica').format()
                        })
                        localStorage.setItem('addDaysToDueDate', event.target.value)
                      }
                    }}
                  />
                </Grid>
              </Grid>
              {settings && settings.useInvoiceSupplyDate && invoiceType === 'INVOICE' &&
              <Grid item style={{ width: 187 }}>
                <SupplyDate supplyDate={invoice.supplyDate} updateInvoice={updateInvoice}/>
              </Grid>
              }
              {settings && settings.contractLabel &&
              <Grid item style={{ minWidth: 180, flexGrow: 1, maxWidth: 500 }}>
                <TextField
                  id="standard-name"
                  label={settings.contractLabel}
                  variant={'outlined'}
                  size={'small'}
                  value={invoice.contractId}
                  style={{ width: '100%' }}
                  onChange={(event) => {
                    if (event.target.value && event.target.value.replace(/\s/g, '')) {
                      updateInvoice({ contractId: event.target.value.replace(/\s/g, '') })
                    } else {
                      updateInvoice({ contractId: '' })
                    }
                  }}
                />
              </Grid>
              }
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={11}>

          <Box p={2} style={{ textAlign: 'center' }}>
            {currentTcrData && currentTcrData.inventory && currentTcrData.inventory.length > 0 ? invoice.items.map((item, idx) => {
              return <InvoiceItem
                key={item.id}
                idx={idx}
                editingItem={item}
                useExemptFromVAT={settings.useExemptFromVAT}
                useInvoiceItemNote={settings.useInvoiceItemNote}
                canChangeInvoiceItemTotal={settings.canChangeInvoiceItemTotal}
                focusFirstInvoiceItem={focusFirstInvoiceItem}
                setFocusFirstInvoiceItem={setFocusFirstInvoiceItem}
                inventory={currentTcrData && currentTcrData.inventory ? currentTcrData.inventory.filter(i => !i.deleted).sort((a, b) => collator.compare(a.sku, b.sku)) : []}
                addInvoiceItem={addInvoiceItem}
                isIssuerInVAT={currentSubscriber.isIssuerInVAT}
                isForeignBuyer={isForeignBuyer}
                priceWoVatForeignInvoice={priceWoVatForeignInvoice}
                setItem={(item, idx) => {
                  let items = [...invoice.items]
                  items[idx] = item
                  dispatch(setInvoice({
                      ...invoice,
                      items
                    }
                  ))
                }}
                editInvoiceItem={editInvoiceItem}
                deleteInvoiceItem={deleteInvoiceItem}
                invoiceItemsIds={invoice.items.filter(i => !!i.id).map(i => i.id)}
                addItemButtonRef={addItemButtonRef}
                setOpenProductDialog={setOpenProductDialog}
              />
            }) : null
            }
          </Box>
          <Box p={3} style={{ textAlign: 'center' }}>
            <Button
              ref={addItemButtonRef}
              disabled={invoice.items.filter(i => !i.id || i.quantity <= 0).length > 0}
              onClick={() => addInvoiceItem()}
              variant="contained"
              size="small"
              color="default">
              <PlusOne/>
            </Button>
          </Box>
          <Box p={3}>
            {templates && templates.length > 0 ?
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    id="selectTemplate"
                    value={invoiceTemplate}
                    options={templates}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    onChange={(event, value) => {
                      dispatch(setInvoiceTemplate(value))
                    }}
                    renderInput={(params) =>
                      <TextField {...params}
                                 label="Šablon"
                                 placeholder="Izaberite šablon"
                                 variant="outlined"/>}/>

                  {invoiceTemplate && invoiceTemplate.jsonSchema &&
                  <Form
                    schema={JSON.parse(invoiceTemplate.jsonSchema)}
                    formData={invoiceTemplateFormData}
                    uiSchema={JSON.parse(invoiceTemplate.uiSchema)}
                    onChange={(e) => {
                      dispatch(setInvoiceTemplateFormData({
                        ...invoiceTemplateFormData,
                        ...e.formData
                      }))
                    }}
                  />}
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                  {invoiceTemplate && invoiceTemplateFormData ? <Grid item sm={12}>
                      <div
                        contentEditable={'false'}
                        suppressContentEditableWarning={true}
                        ref={noteRef}
                        style={{
                          border: '1px solid grey',
                          borderRadius: 5,
                          padding: 5,
                          fontSize: 16,
                          float: 'left',
                          width: '100%',
                          lineHeight: '18px'
                        }}
                        onChange={(event) => {
                          updateInvoice({
                            note: event.target.value
                          })
                        }}
                      >
                        <JsxParser
                          jsx={invoiceTemplate.template}
                          bindings={{
                            ...invoiceTemplateFormData
                          }}
                        />
                      </div>
                    </Grid> :
                    <Grid item sm={12}>
                      <div
                        contentEditable={'true'}
                        ref={noteRef}
                        style={{
                          border: '1px solid grey',
                          borderRadius: 5,
                          padding: 5,
                          fontSize: 16
                        }}
                        onChange={(event) => {
                          updateInvoice({
                            note: event.target.value
                          })
                        }}
                        onPaste={(e) => {
                          e.preventDefault()
                          let text = (e.originalEvent || e).clipboardData.getData('text/plain');
                          text = text.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
                          document.execCommand("insertHTML", false, text);
                        }}
                      >
                      </div>
                    </Grid>
                  }
                </Grid>
              </Grid> :
              <Grid item sm={12}>
                <div
                  contentEditable={'true'}
                  ref={noteRef}
                  style={{
                    border: '1px solid grey',
                    borderRadius: 5,
                    padding: 5,
                    fontSize: 16
                  }}
                  onChange={(event) => {
                    console.log('OON CHANGE')
                    updateInvoice({
                      note: event.target.value
                    })
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
                    text = text.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
                    document.execCommand("insertHTML", false, text);
                  }}
                >
                </div>
              </Grid>
            }
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
          <Box p={1}>
            <Grid container>
              <Grid item xs={6} sm={3} md={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent className={classes.card}>
                    <Typography variant="h5">
                      {!isNaN(invoice.discountAmount) ? invoice.discountAmount.toFixed(2) : '0.00'}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Popust %
                    </Typography>
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      id="discountPercentage"
                      name="discountPercentage"
                      size="small"
                      type="number"
                      style={{ maxWidth: 115 }}
                      value={invoice.discountPercentage}
                      onFocus={(event) => event.target.select()}
                      inputProps={{
                        style: {
                          paddingRight: 2
                        }
                      }}
                      onChange={event => {
                        if (!isNaN(Number(event.target.value)) && Number(event.target.value) >= 0) {
                          updateInvoice({
                            discountPercentage: Number(event.target.value),
                            changeAll: true
                          })
                        }
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3} md={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent className={classes.card}>
                    <Typography variant="h5">
                      {!isNaN(invoice.totPriceWoVAT) ? invoice.totPriceWoVAT.toFixed(2) : '0.00'}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Ukupno bez poreza
                    </Typography>

                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sm={3} md={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent className={classes.card}>
                    <Typography variant="h5">
                      {!isNaN(invoice.totVATAmt) ? invoice.totVATAmt.toFixed(2) : '0.00'}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Porez
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {isForeignBuyer && <Grid item xs={6} sm={3} md={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent className={classes.card}>
                    <Typography variant="h5">
                      {!isNaN(invoice.goodsExAmt) ? invoice.goodsExAmt.toFixed(2) : '0.00'}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Izuzeto od oporezivanja
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>}
              <Grid item xs={6} sm={3} md={12} style={{ paddingTop: 5, paddingBottom: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent className={classes.card}>
                    <Typography variant="h5">
                      {!isNaN(invoice.totPrice) ? invoice.totPrice.toFixed(2) : '0.00'}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Ukupno
                    </Typography>

                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12}>
                <Grid container justify="center">
                  <Box p={1} style={{ textAlign: 'center' }}>
                    <Button
                      disabled={
                        !invoice ||
                        !invoice.client ||
                        invoice.items.filter(i => i.quantity > 0).length === 0 ||
                        invoice.items.filter(i => i.quantity <= 0).length > 0 ||
                        (invoice.dueDate &&
                          !moment(invoice.dueDate).isValid()) ||
                        invoice.operatorCode === '' ||
                        invoice.totPrice <= 0 ||
                        !currentSubscriber.active ||
                        !currentTcr.noncacheInvoiceCert ||
                        currentStore.allowedInvoiceType === 'CASH' ||
                        (
                          currentSubscriber.activeDueDate &&
                          moment(currentSubscriber.activeDueDate).isValid() &&
                          moment().isAfter(moment(currentSubscriber.activeDueDate).endOf('day'))
                        )
                      }
                      onClick={async () => {
                        const nextInvNo = await getInvoicesCount()
                        if (nextInvNo > 0) {
                          setNextInvoiceNumber(nextInvNo)
                        } else {
                          dispatch(setErrorSnackbar('Greška, provjerite vezu sa internetom i pokušajte ponovo'))
                        }
                      }}
                      variant="contained"
                      size="large"
                      color="primary">
                      Fiskalizuj
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {/*<Grid item sm={12}>
                <Grid container justify="center">
                  <Box p={1} style={{ textAlign: 'center' }}>
                    <Button
                      onClick={async () => {
                        dispatch(resetInvoice())
                      }}
                      variant="contained"
                      size="small"
                      color="secondary">
                      Obriši
                    </Button>
                  </Box>
                </Grid>
              </Grid>*/}
            </Grid>
          </Box>
        </Grid>

        {openProductDialog && <ProductDialog
          openProductDialog={openProductDialog}
          setOpenProductDialog={setOpenProductDialog}
          postInventory={postInventory}
          useGoodsReceipt={currentTcr.useGoodsReceipt}
          isIssuerInVAT={currentSubscriber.isIssuerInVAT}
          inventory={currentTcrData.inventory}
        />}

        {openClientDialog && <ClientDialog
          openClientDialog={openClientDialog}
          setOpenClientDialog={setOpenClientDialog}
          postClient={postClient}
        />}

        {nextInvoiceNumber &&
        <Dialog
          disableBackdropClick
          fullWidth
          maxWidth="md"
          open={!!nextInvoiceNumber}
          onClose={() => {
            setNextInvoiceNumber(null)
          }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            {invoice &&
            <NonCacheInvoice
              settings={settings}
              nextInvoiceNumber={nextInvoiceNumber}
              currentSubscriber={currentSubscriber}
              currentStore={currentStore}
              invoice={{
                invoice: {
                  ...invoice,
                  yearOfIssuance: (new Date()).getFullYear().toString(),
                  businUnitCode: currentTcr.businUnitCode,
                  isIssuerInVAT: currentSubscriber.isIssuerInVAT,
                  softCode: process.env.REACT_APP_SOFT_CODE,
                  issueDateTime: moment().tz('Europe/Podgorica').format(),
                  tcrCode: currentTcr.TCR,
                  payMethods: [{ amt: invoice.totPrice, type: 'ACCOUNT' }],
                  items: invoice.items.filter(i => !!i.id && i.quantity > 0),
                  invoiceType: invoiceType,
                  seller: {
                    'idNum': currentSubscriber.taxIdentifier,
                    'idType': currentSubscriber.taxIdentifierType,
                    'name': currentSubscriber.name,
                    'vatNumber': currentSubscriber.vatNumber,
                    'address': currentSubscriber.address,
                    'town': currentSubscriber.town,
                    'country': currentSubscriber.country,
                    'isIssuerInVAT': currentSubscriber.isIssuerInVAT,
                    'active': currentSubscriber.active,
                    'bankAccounts': currentSubscriber.bankAccounts
                  },
                  note: noteRef && noteRef.current ? noteRef.current.innerHTML : ''
                }
              }}/>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNextInvoiceNumber(false)} variant="outlined" color="primary">
              Zatvori
            </Button>
            <Button onClick={async () => {
              setNextInvoiceNumber(false)
              await postInvoice()
            }} variant="contained" color="primary">
              Potvrdi
            </Button>
          </DialogActions>
        </Dialog>}

        {showImportModal &&
        <Dialog
          disableBackdropClick
          fullWidth
          maxWidth="md"
          open={showImportModal}
          onClose={() => {
            setShowImportModal(false)
          }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <textarea value={importTextValue} onChange={(e) => setImportTextValue(e.target.value)}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowImportModal(false)} variant="outlined" color="primary">
              Zatvori
            </Button>
            <Button onClick={async () => {
              console.log('aaaaaaa', importTextValue)
              // console.log(readString(importTextValue))
              const importData = await readString(importTextValue);
              console.log(importData.data);

              const items = []

              importData.data.forEach(i => {
                const p = currentTcrData.inventory.find(product => product.sku.replace(/\s/g, "").toLowerCase() == i[0].replace(/\s/g, "").toLowerCase());
                if (p) {
                  const item = new InvoiceItemModel({
                    id: p.id,
                    name: p.name,
                    quantity: roundDecimal(Number(i[2].trim()) / 100, 3),
                    code: p.sku,
                    vatRate: roundDecimal(Number(i[6].trim()) / 100, 0),
                    rebate: 0,
                    unitOfMeasure: p.unitOfMeasure
                  })

                  item.update({
                    price: Number((Number(Number(i[7].trim()) / 100) / item.quantity)).toFixed(4)
                  })

                  items.push(item)
                } else {
                  console.log(i[0],' ---- ',  i[1])
                }
              })

              invoice.update({
                items
              })

              setShowImportModal(false)

            }} variant="contained" color="primary">
              Uvezi
            </Button>
          </DialogActions>
        </Dialog>}
      </Grid>
    </Paper>
  )
};

