import * as React from 'react';
import {
    AppBar,
    Box,
    Button,
    Paper, Tab, Tabs
} from "@material-ui/core";
import {useCallback, useEffect, useState} from "react";
import {setErrorSnackbar, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch} from "react-redux";
import {useAxios} from "../../utils/hooks";
import TabPanel from "../../components/TabPanel";
import {StoreModel} from "../../models/StoreModel";
import Store from "./Store";
import NewStore from "./NewStore";
import { Add } from '@material-ui/icons'

const ButtonInTabs = ({className, onClick, children}) => {
    return <Button className={className} onClick={onClick} children={children}/>;
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tab-panel-${index}`,
    };
}

export default function SubscriberStores({subscriberId, subscriberTIN, stores, addStore, updateStore, certificates, users, isIssuerInVAT}) {

    const [tabIndex, setTabIndex] = useState(0);
    const [mounted, setMounted] = useState(false);

    const dispatch = useDispatch();
    const axiosInstance = useAxios();

    const handleTabIndexChange = (event, newValue) => {
        if (mounted) setTabIndex(newValue);
    };

    useEffect(() => {
        setMounted(true);

        return () => {
            setMounted(false);
        }
    }, []);

    useEffect(() => {
        if (mounted) setTabIndex(stores.length - 1);
    }, [stores.length]);

    const postStore = useCallback((s) => {
        const store = new StoreModel();
        store.update(s);

        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            if (!store.id) {
                axiosInstance.current.post(`/admin/subscriber/${subscriberId}/store`, store)
                    .then(({data}) => {
                        addStore({
                            ...store,
                            id: data.id
                        });
                        dispatch(setSuccessSnackbar("Poslovna jedinica je sačuvana"));
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            } else {
                axiosInstance.current.put(`/admin/subscriber/${subscriberId}/store/${store.id}`, store)
                    .then(() => {
                        updateStore(store);
                        dispatch(setSuccessSnackbar("Poslovna jedinica je izmijenjena"));
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        }
    }, [axiosInstance, dispatch, subscriberId, addStore, updateStore]);

    return (
        <Paper elevation={3}>
            <Box>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabIndexChange}
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="scrollable force tabs stores"
                    >
                        {stores.map(s => {
                            return <Tab key={`tab_${s.id}`} label={s.name} {...a11yProps(0)} />
                        })}
                        <ButtonInTabs
                            onClick={() => {
                                if (mounted) setTabIndex(stores && stores.length > 0 ? stores.length : 0);
                            }}
                        >
                            <Add color="secondary"/>
                        </ButtonInTabs>
                    </Tabs>
                </AppBar>
                {stores.map((s, idx) => {
                    return <TabPanel key={`tab_panel_${s.id}`} value={tabIndex} index={idx}>
                        <Box p={2}>
                            <Store initialStore={s} postStore={postStore} subscriberId={subscriberId} subscriberTIN={subscriberTIN}
                                   certificates={certificates} users={users} isIssuerInVAT={isIssuerInVAT}/>
                        </Box>
                    </TabPanel>
                })}
                <TabPanel key={`tab_panel_new`} value={tabIndex} index={stores && stores.length > 0 ? stores.length : 0}>
                    <Box p={2}>
                        <NewStore postStore={postStore}/>
                    </Box>
                </TabPanel>
            </Box>
        </Paper>
    );
}
