import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getTheme } from '../Setting/settingsReducer'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}))

const Tile = ({ text, value, color, icon, className, doNotRound, decimalPlaces, onClick, selected, ...rest }) => {
  const classes = useStyles()
  const theme = useSelector(getTheme)

  return (
    <Card
      onClick={() => onClick && onClick()}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent
        style={{backgroundColor: selected ? theme.palette.primary.main : "", padding: 10}}
      >
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item style={{paddingRight: 0}}>
            <Typography
              style={{color: selected ? "#fff" : "#000"}}
              variant="h6"
            >
              {text.toUpperCase()}
            </Typography>
            <Typography
              style={{color: selected ? "#fff" : "#000"}}
              variant="h4"
            >
              {doNotRound ? value : value.toLocaleString('en-US', {minimumFractionDigits: decimalPlaces !== undefined ? decimalPlaces : 2, maximumFractionDigits: decimalPlaces !== undefined ? decimalPlaces : 2})}
            </Typography>
          </Grid>
          <Grid item style={{paddingLeft: 0}}>
            <Avatar style={{ backgroundColor: color }}>
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

Tile.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string
}

export default Tile