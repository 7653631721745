import * as React from 'react';
import {
    Box, Button,
    Paper,
    TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useState} from "react";
import {StoreModel} from "../../models/StoreModel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { maxNumChars, removeLineBreaks } from '../../utils/helpers'

export default function NewStore({postStore}) {

    const [store, setStore] = useState(new StoreModel());

    const handleChange = (event) => {
        setStore({
            ...store,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeOnBlur = (name, value) => {
        setStore({
            ...store,
            [name]: value.trim()
        })
    }

    const handleSetAllowedInvoiceType = (event) => {
        let allowedInvoiceType;
        if (event.target.name === 'cash') {
            if (event.target.checked) {
                allowedInvoiceType = 'BOTH';
            } else {
                allowedInvoiceType = 'NONCASH';
            }
        } else {
            if (event.target.checked) {
                allowedInvoiceType = 'BOTH';
            } else {
                allowedInvoiceType = 'CASH';
            }
        }

        setStore({
            ...store,
            allowedInvoiceType
        });
    }

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            autoComplete='off'
                            required
                            autoFocus
                            id="name"
                            name="name"
                            label="Naziv"
                            fullWidth
                            value={store.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            autoComplete='off'
                            error={!store.businUnitCode.match("^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}")}
                            helperText={!store.businUnitCode.match("^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}") ? "Unesite ispravan code" : ""}
                            required
                            id="businUnitCode"
                            name="businUnitCode"
                            label="PU code"
                            fullWidth
                            value={store.businUnitCode}
                            onChange={handleChange}
                            onBlur={(event) => {
                                handleChangeOnBlur(event.target.name, event.target.value)
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                setStore({
                                    ...store,
                                    businUnitCode: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 10)
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControlLabel
                            control={<Checkbox
                                color="secondary"
                                name="cash"
                                disabled={store.allowedInvoiceType && store.allowedInvoiceType === "CASH"}
                                checked={!store.allowedInvoiceType || (store.allowedInvoiceType && ["CASH", "BOTH"].includes(store.allowedInvoiceType))}
                                onChange={handleSetAllowedInvoiceType}/>}
                            label="Gotovinski računi"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControlLabel
                            control={<Checkbox
                                color="secondary"
                                name="noncash"
                                disabled={store.allowedInvoiceType && store.allowedInvoiceType === "NONCASH"}
                                checked={!store.allowedInvoiceType || (store.allowedInvoiceType && ["NONCASH", "BOTH"].includes(store.allowedInvoiceType))}
                                onChange={handleSetAllowedInvoiceType}/>}
                            label="Bezgotovinski računi"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            autoComplete='off'
                            required
                            id="address"
                            name="address"
                            label="Adresa"
                            fullWidth
                            value={store.address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          autoComplete='off'
                          required
                          id="city"
                          name="city"
                          label="Grad"
                          fullWidth
                          value={store.city}
                          onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete='off'
                            id="note"
                            name="note"
                            label="Napomena"
                            fullWidth
                            value={store.note}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'right'}}>
                        <Button onClick={() => {
                            postStore(store);
                        }} variant="contained" color="primary">
                            Sačuvaj
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
