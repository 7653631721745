import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {useDispatch, useSelector} from 'react-redux'
import {getIsLoading, setErrorSnackbar} from '../mainReducer'
import { statesList } from '../../utils/model'
import moment from 'moment'
import { htmlSpecialChars, maxNumChars, removeLineBreaks } from '../../utils/helpers'
import { Autocomplete } from '@material-ui/lab'

const initialClient = {
  city: '',
  address: '',
  country: 'MNE',
  deleted: false,
  id: '',
  idNumber: '',
  name: '',
  bankAccount: '',
  vatNumber: '',
  phone: '',
  createdAt: moment().valueOf()
}

const ClientDialog = (
  {
    editingClient,
    setEditingClient,
    openClientDialog,
    setOpenClientDialog,
    postClient,
      clients
  }) => {

  const dispatch = useDispatch()
  const [createNewAfterSave, setCreateNewAfterSave] = useState(false)
  const [client, setClient] = useState(initialClient)
  const nameRef = useRef()
  const isLoading = useSelector(getIsLoading)
  const [formReseted, setFormReseted] = useState(true)

  useEffect(() => {
    if (editingClient) {
      setClient({
        id: editingClient.id || '',
        name: editingClient.name || '',
        address: editingClient.address || '',
        city: editingClient.city || '',
        country: editingClient.country || '',
        idNumber: editingClient.idNumber || '',
        bankAccount: editingClient.bankAccount || '',
        vatNumber: editingClient.vatNumber || '',
        phone: editingClient.phone || '',
        createdAt: editingClient.createdAt || ''
      })
      setFormReseted(true)
    } else {
      setClient(initialClient)
      setFormReseted(true)
    }

  }, [editingClient])

  useEffect(() => {
    if (formReseted && nameRef.current) {
      nameRef.current.focus()
      setFormReseted(false)
    }
  }, [formReseted])

  const handleChange = (event) => {
    setClient({
      ...client,
      [event.target.name]: htmlSpecialChars(event.target.value)
    })
  }

  const handleChangeOnBlur = (name, value) => {
    setClient({
      ...client,
      [name]: value.trim()
    })
  }

  const handleChangeCountry = (value) => {
    const country = value ? value.key : null
    setClient({
      ...client,
      idNumber: !!client.idNumber && country === 'MNE' ? client.idNumber.replace(/\D/g, '') : client.idNumber,
      country
    })
  }

  const handleChangeId = (value) => {
    setClient({
      ...client,
      idNumber: value
    })
  }

  const isDomesticClient = client.country === 'MNE'
  let idErrorMessage = ''
  if (isDomesticClient && ![8, 13].includes(client.idNumber.trim().length)) {
    idErrorMessage = '8 ili 13 cifara za partnera iz Crne Gore'
  } else if (!isDomesticClient && client.idNumber.trim().length < 6) {
    idErrorMessage = 'min. 6 karaktera'
  }

  const selectedCountry = statesList.find(s => s.key === client.country)

  return (
    <Dialog
      disableBackdropClick
      maxWidth="lg"
      open={openClientDialog}
      onClose={() => {
        setEditingClient && setEditingClient(null)
        setOpenClientDialog(false)
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>{editingClient ? 'Izmjena partnera' : 'Unos novog partnera'}</DialogTitle>
      <DialogContent>
        {client && <Paper elevation={3}>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="off"
                  required
                  id="name"
                  name="name"
                  label="Naziv"
                  error={client.name.trim().length < 2}
                  helperText={`${client.name.length}/100`}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  value={client.name}
                  onChange={handleChange}
                  inputRef={nameRef}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      name: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 100)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  required
                  error={client.address.trim().length < 2}
                  helperText={`${client.address.length}/200`}
                  id="address"
                  name="address"
                  label="Adresa"
                  inputProps={{ maxLength: 200 }}
                  fullWidth
                  value={client.address}
                  onChange={handleChange}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      address: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 200)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  required
                  error={client.city.trim().length < 2}
                  helperText={`${client.city.length}/200`}
                  id="city"
                  name="city"
                  label="Grad"
                  fullWidth
                  value={client.city}
                  onChange={handleChange}
                  inputProps={{ maxLength: 100 }}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      city: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 100)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="combo-box-country"
                  size="small"
                  value={selectedCountry}
                  options={statesList}
                  autoComplete={false}
                  getOptionSelected={(option) => {
                    return !!selectedCountry && option.key === selectedCountry.key
                  }}
                  getOptionLabel={(option) => option.value}
                  renderOption={option => <span>{option.value}</span>}
                  style={{ width: '100%' }}
                  onChange={(event, value) => {
                    handleChangeCountry(value)
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                               error={!selectedCountry}
                               label="Država"
                               required/>}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  required
                  error={!!idErrorMessage}
                  helperText={idErrorMessage}
                  id="idNumber"
                  name="idNumber"
                  label="PIB/JMB"
                  fullWidth
                  value={client.idNumber}
                  onChange={(e) => {
                    if (isDomesticClient) {
                      handleChangeId(e.target.value.replace(/\D/g, ''))
                    } else {
                      handleChangeId(e.target.value.replace(/ /g, ''))
                    }
                  }}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      idNumber: removeLineBreaks(e.clipboardData.getData('Text'))
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  id="vatNumber"
                  name="vatNumber"
                  label="PDV"
                  fullWidth
                  value={client.vatNumber}
                  onChange={handleChange}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      vatNumber: removeLineBreaks(e.clipboardData.getData('Text'))
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  id="bankAccount"
                  name="bankAccount"
                  label="Žiro račun"
                  fullWidth
                  value={client.bankAccount}
                  onChange={handleChange}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      bankAccount: removeLineBreaks(e.clipboardData.getData('Text'))
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  id="phone"
                  name="phone"
                  label="Telefon"
                  fullWidth
                  value={client.phone}
                  onChange={handleChange}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setClient({
                      ...client,
                      phone: removeLineBreaks(e.clipboardData.getData('Text'))
                    })
                  }}
                />
              </Grid>

            </Grid>
          </Box>
        </Paper>}
      </DialogContent>
      <DialogActions>
        {!editingClient && <FormControlLabel
          control={<Checkbox
            tabIndex={-1}
            color="secondary"
            checked={createNewAfterSave}
            onChange={() => setCreateNewAfterSave(!createNewAfterSave)}/>
          }
          label="Brzi unos"
        />}
        <div style={{ flex: '1 0 0' }}/>
        <Button color="primary" onClick={() => {
          setEditingClient && setEditingClient(null)
          setOpenClientDialog(false)
        }}>
          Zatvori
        </Button>
        <Button
          onClick={() => {
            postClient(client, !editingClient, !createNewAfterSave)
            setClient(initialClient)
            nameRef.current && nameRef.current.focus()
          }}
          variant="contained"
          color="primary"
          disabled={
            isLoading ||
            client.address.trim().length < 2 ||
            client.city.trim().length < 2 ||
            !selectedCountry ||
            client.name.trim().length < 2 ||
            !!idErrorMessage
          }
        >
          Sačuvaj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ClientDialog