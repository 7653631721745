import * as React from 'react';
import {
    AppBar,
    Box, Button, Dialog, DialogTitle,
    Tab, Tabs,
} from "@material-ui/core";
import {useCallback, useEffect, useState} from "react";
import TabPanel from "../../components/TabPanel";
import Tcr from "./Tcr";
import {TcrModel} from "../../models/TcrModel";
import {setErrorSnackbar, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch} from "react-redux";
import {useAxios} from "../../utils/hooks";
import QRCode from "qrcode.react";
import { Add } from '@material-ui/icons'


const ButtonInTabs = ({className, onClick, children}) => {
    return <Button className={className} onClick={onClick} children={children}/>;
};

export default function TcrList({tcrs, certificates, subscriberId, storeId, addTcr, updateTcr, users, businUnitCode, subscriberTIN, isIssuerInVAT}) {

    const [tabIndex, setTabIndex] = useState(0);
    const [pin, setPin] = useState();
    const [openPinDialog, setOpenPinDialog] = useState(false);

    const dispatch = useDispatch();
    const axiosInstance = useAxios();

    const handleTabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleClose = () => {
        setPin(null);
    };

    useEffect(() => {
        if (pin) {
            setOpenPinDialog(true);
        } else {
            setOpenPinDialog(false);
        }
    }, [pin]);

    const registerTcr = useCallback((t) => {
        if (!t.TCR) {
            const tcr = new TcrModel();
            tcr.update(t);
            dispatch(setIsLoading(true));
            if (!!axiosInstance.current) {
                axiosInstance.current.post(`/admin/tcr/${tcr.id}/_register`, {})
                    .then(({data}) => {
                        tcr.update(data);
                        updateTcr(tcr);
                        dispatch(setSuccessSnackbar("ENU je registrovan"));
                    })
                    .catch(err => {
                        const faultString = err && err.response && err.response.data && err.response.data.error ? err.response.data.error.faultString : "";
                        dispatch(setErrorSnackbar(`Greška${faultString ? ": " + faultString : ""}`));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        }
    }, [axiosInstance, dispatch, updateTcr]);

    const generatePin = useCallback((t) => {
        const tcr = new TcrModel();
        tcr.update(t);
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            axiosInstance.current.post(`/admin/subscriber/${subscriberId}/store/${storeId}/tcr/${tcr.id}/_pin`, {})
                .then(({data}) => {
                    setPin(data.pin);
                    dispatch(setSuccessSnackbar(`PIN je kreiran. Traje ${data.expiresIn / 60} minuta!`));
                })
                .catch(err => {
                    console.log(err);
                    dispatch(setErrorSnackbar("Greška"));
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, subscriberId, storeId]);

    const postTcr = useCallback((t, sync) => {
        const tcr = new TcrModel();
        tcr.update(t);

        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            if (!tcr.id) {
                axiosInstance.current.post(`/admin/subscriber/${subscriberId}/store/${storeId}/tcr`, tcr.toJson())
                    .then(({data}) => {
                        addTcr(data);
                        dispatch(setSuccessSnackbar("ENU je sačuvan"));
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            } else {
                axiosInstance.current.put(`/admin/subscriber/${subscriberId}/store/${storeId}/tcr/${tcr.id}`, tcr.toJson())
                    .then(() => {
                        if (sync) {
                            axiosInstance.current.get(`/admin/subscriber/${subscriberId}/store/${storeId}/tcr/${tcr.id}`)
                                .then(({data}) => {
                                    updateTcr(data);
                                    dispatch(setSuccessSnackbar("ENU je izmijenjen"));
                                });
                        } else {
                            updateTcr(tcr);
                            dispatch(setSuccessSnackbar("ENU je izmijenjen"));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        }
    }, [axiosInstance, dispatch, subscriberId, storeId, addTcr, updateTcr]);

    return (
        <Box>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabIndexChange}
                    scrollButtons="on"
                    variant="scrollable"
                    aria-label="scrollable force tabs tcrs"
                >
                    {tcrs.map(s => {
                        return <Tab key={`tab_${s.id}`}
                                    label={<span style={{color: s.TCR ? 'primary' : 'red'}}>{s.name}</span>}/>
                    })}
                    <ButtonInTabs
                        onClick={() => {
                            setTabIndex(tcrs && tcrs.length > 0 ? tcrs.length : 0);
                        }}
                    >
                        <Add color="secondary"/>
                    </ButtonInTabs>
                </Tabs>
            </AppBar>
            {tcrs.map((t, idx) => {
                return <TabPanel key={`tabpanel_${t.id}`} value={tabIndex} index={idx}>
                    <Box p={2}>
                        <Tcr initialTcr={t} registerTcr={registerTcr} postTcr={postTcr} certificates={certificates}
                             subscriberId={subscriberId} subscriberTIN={subscriberTIN} isIssuerInVAT={isIssuerInVAT}
                             generatePin={generatePin} setPin={setPin} users={users} businUnitCode={businUnitCode}/>
                    </Box>
                </TabPanel>
            })}
            <TabPanel key={`tabpanel_new`} value={tabIndex} index={tcrs && tcrs.length > 0 ? tcrs.length : 0}>
                <Box p={2}>
                    <Tcr initialTcr={new TcrModel()} postTcr={postTcr} certificates={certificates}/>
                </Box>
            </TabPanel>
            {pin &&
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openPinDialog}>
                <DialogTitle id="dialog-pin" style={{textAlign: 'center'}}>{pin}</DialogTitle>
                <Box p={2}>
                    <QRCode
                        value={pin}
                        size={256}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"svg"}
                    />
                </Box>
            </Dialog>
            }
        </Box>
    );
}
