import * as React from 'react';
import {Box, Button} from "@material-ui/core";
import MaterialTable from "material-table";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';

import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentSubscriber,
    getCurrentTcr,
    getCurrentTcrData,
    setCurrentTcrData,
    setIsLoading
} from '../mainReducer'
import {useAxios} from "../../utils/hooks";
import {statesList} from "../../utils/model";
import {localization, tableIcons} from "../../utils/material-table";
import { Add, Delete } from '@material-ui/icons'
import Edit from '@material-ui/icons/Edit'
import ClientDialog from './ClientDialog'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import moment from 'moment'
import { removeLineBreaks } from '../../utils/helpers'
import { useApi } from '../../api/api'

export default function Clients() {

    const currentSubscriber = useSelector(getCurrentSubscriber);
    const currentTcr = useSelector(getCurrentTcr);
    const currentTcrData = useSelector(getCurrentTcrData);
    const [clients, setClients] = useState([]);
    const axiosInstance = useAxios();
    const dispatch = useDispatch();
    const [openClientDialog, setOpenClientDialog] = useState(false)
    const [editingClient, setEditingClient] = useState(null)
    const [clientToDelete, setClientToDelete] = useState(null)
    const api = useApi()

    useEffect(() => {
        if (currentTcrData && currentTcrData.clients) {
            setClients(currentTcrData.clients.map(i => {return {...i}}).filter(i => !i.deleted));
        } else {
            setClients([]);
        }
    }, [currentTcrData]);

    useEffect(() => {
        setOpenClientDialog(!!editingClient)
    }, [editingClient])

    const postClient = (client, isNew, closeDialog) => {
        if (axiosInstance.current) {
            dispatch(setIsLoading(true))
            if (isNew) {
                delete client.tableData;
                client = {
                    ...client,
                    name: removeLineBreaks(client.name),
                    city: removeLineBreaks(client.city),
                    address: removeLineBreaks(client.address),
                    idNumber: removeLineBreaks(client.idNumber),
                    bankAccount: removeLineBreaks(client.bankAccount),
                    vatNumber: removeLineBreaks(client.vatNumber),
                    phone: removeLineBreaks(client.phone),
                    id: uuidv4(),
                    createdAt: moment().valueOf()
                }
                axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_clients`, client)
                  .then(() => {
                      addClient({...client})
                      setEditingClient(null)
                      if (closeDialog) setOpenClientDialog(false)
                  })
                  .finally(() => {
                      dispatch(setIsLoading(false))
                  })
            } else {
                client = {
                    ...client,
                    updatedAt: moment().valueOf()
                }
                delete client.tableData;
                axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_clients`, client)
                  .then(() => {
                      updateClient({...client})
                      setEditingClient(null)
                      setOpenClientDialog(false)
                  })
                  .finally(() => {
                      dispatch(setIsLoading(false))
                  })
            }
        }
    }

    const addClient = (client) => {
        dispatch(setCurrentTcrData({
            ...currentTcrData,
            clients: [client, ...currentTcrData.clients]
        }));
    }

    const updateClient = (client) => {
        dispatch(setCurrentTcrData({
            ...currentTcrData,
            clients: currentTcrData.clients.map(i => {
                return i.id === client.id ? client : i;
            })
        }));
    }

    const deleteClient = async () => {
        const deletedClient = {
            ...clientToDelete,
            deleted: true,
            updatedAt: moment().valueOf()
        }
        delete deletedClient.tableData;
        setClientToDelete(null)
        dispatch(setIsLoading(true))
        axiosInstance.current.put(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata/_clients`, deletedClient)
          .then(() => {
              updateClient(deletedClient)
          })
          .finally(() => {
              dispatch(setIsLoading(false))
          })
    }

    return (
        <Box style={{minHeight: 650, marginTop: 10}}>
            {currentTcrData &&
                <MaterialTable
                    title="Partneri"
                    icons={tableIcons}
                    columns={[
                        {
                            field: 'name', title: 'Naziv', flex: 1,
                            validate: rowData => !rowData || (!rowData.name || rowData.name.trim().length === 0) ? {
                                isValid: false,
                                helperText: 'Ne smije biti prazno polje'
                            } : true
                        },
                        {
                            field: 'idNumber', title: 'PIB', width: 150, type: 'string',
                            validate: rowData => !rowData || (!rowData.idNumber || !rowData.idNumber.toString().match(/^\d{8}(?:\d{5})?$/)) ? {
                                isValid: false,
                                helperText: 'Mora imati 8 ili 13 cifara'
                            } : true
                        },
                        {field: 'vatNumber', title: 'PDV', width: 150},
                        {field: 'bankAccount', title: 'ZR', width: 150},
                        {field: 'address', title: 'Adresa', width: 150},
                        {field: 'city', title: 'Grad', width: 150},
                        {field: 'phone', title: 'Tel.', width: 150},
                        {field: 'country', title: 'Država', width: 150,
                            initialEditValue: "MNE",
                            lookup: statesList.reduce((acc, next) => {return {...acc, [next.key]: next.value}}, {}),
                            validate: rowData => !rowData || (!rowData.country || rowData.country.toString().trim().length === 0) ? {
                                isValid: false,
                                helperText: 'Ne smije biti prazno polje'
                            } : true},
                        {
                            field: "",
                            headerName: "",
                            disableClickEventBubbling: true,
                            renderCell: () => {
                                const onClick = () => {};
                                return <Button variant="outlined" color="primary" size="small" onClick={onClick}>Izmijeni</Button>;
                            }
                        }
                    ]}
                    data={clients.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)}
                    options={{
                        rowStyle: rowData => {
                            if (rowData.deleted) {
                                return {backgroundColor: '#ffbaba'};
                            } else if(rowData.country !== 'MNE') {
                                return {color: '#1571bc'};
                            }
                            return {};
                        },
                        pageSize: 10,
                        emptyRowsWhenPaging: true,
                        pageSizeOptions:[5, 10, 25],
                        actionsColumnIndex: -1
                    }}
                    localization={localization}
                    actions={[
                        {
                            icon: () => <Add/>,
                            tooltip: 'Dodaj novi',
                            onClick: (() => {
                                setOpenClientDialog(true)
                            }),
                            isFreeAction: true,
                        },
                        {
                            icon: () => <Edit/>,
                            tooltip: 'Izmijeni',
                            onClick: ((event, rowData) => {
                                setEditingClient(rowData)
                            }),
                            position: 'row'
                        },
                        {
                            icon: () => <Delete/>,
                            tooltip: 'Obriši',
                            onClick: async (event, rowData) => {
                                setClientToDelete({
                                    ...rowData,
                                    loading: true
                                })
                                await api.getLastInvoiceForClient(currentSubscriber.id, currentTcr.id, rowData.id).then(result => {
                                    setClientToDelete({
                                        ...rowData,
                                        canBeDeleted: !result.data?.invoices || (result.data?.invoices && result.data?.invoices.length === 0),
                                        loading: false
                                    })
                                }).catch(() => {
                                    setClientToDelete({
                                        ...rowData,
                                        canBeDeleted: false,
                                        loading: false
                                    })
                                })
                            },
                            position: 'row'
                        },
                    ]}
                />
            }
            {openClientDialog && <ClientDialog
              openClientDialog={openClientDialog}
              setOpenClientDialog={setOpenClientDialog}
              editingClient={editingClient}
              setEditingClient={setEditingClient}
              postClient={postClient}
              clients={currentTcrData.clients}
            />}

            {!!clientToDelete &&
            <ConfirmDialog
              open={!!clientToDelete}
              onConfirm={clientToDelete.canBeDeleted ? deleteClient : null}
              msg={clientToDelete.canBeDeleted ?`Da li ste sigurni da želite da obrišete partnera ${clientToDelete.name} ?` : 'Partner ne može biti obrisan jer u sistemu postoje računi vezani za njega!'}
              closeConfirm={setClientToDelete}
              loading={clientToDelete.loading}
            />
            }
        </Box>
    )
}