import { encode } from 'html-entities'

export class GoodsReceiptItemModel {

  constructor (item) {
    this.id = (item && item.id) || ''
    this.code = (item && encode(item.code)) || ''
    this.name = (item && encode(item.name)) || ''
    this.quantity = (item && Number(item.quantity)) || 0
  }

  update (updatedGoodsReceiptItem) {
    this.id = updatedGoodsReceiptItem.id || this.id
    this.code = encode(updatedGoodsReceiptItem.code) || this.code
    this.name = encode(updatedGoodsReceiptItem.name) || this.name
    this.quantity = Number(updatedGoodsReceiptItem.quantity) || this.quantity
  }

}

