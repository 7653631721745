export class StoreModel {
    constructor() {
        this.id = "";
        this.name = '';
        this.businUnitCode = '';
        this.address = '';
        this.city = '';
        this.note = '';
        this.allowedInvoiceType = 'BOTH';
    }

    update(updatedStore) {
        this.id = updatedStore.id;
        this.name = updatedStore.name;
        this.businUnitCode = updatedStore.businUnitCode;
        this.address = updatedStore.address;
        this.city = updatedStore.city;
        this.note = updatedStore.note;
        this.allowedInvoiceType = updatedStore.allowedInvoiceType;
    }

}

