import { GoodsReceiptItemModel } from './GoodsReceiptItemModel'

export class GoodsReceiptModel {

  constructor () {
    this.yearOfIssuance = ''
    this.issueDateTime = ''
    this.invOrdNum = 0
    this.operatorCode = ''
    this.tcrCode = ''
    this.businUnitCode = ''
    this.items = [new GoodsReceiptItemModel()]
    this.initialStockStatus = false
    this.note = ''
  }

  update (updatedGoodsReceipt) {
    this.yearOfIssuance = updatedGoodsReceipt.yearOfIssuance || this.yearOfIssuance;
    this.issueDateTime = updatedGoodsReceipt.issueDateTime || this.issueDateTime
    this.invOrdNum = updatedGoodsReceipt.invOrdNum || this.invOrdNum
    this.operatorCode = updatedGoodsReceipt.operatorCode !== undefined ? updatedGoodsReceipt.operatorCode : this.operatorCode
    this.tcrCode = updatedGoodsReceipt.tcrCode || this.tcrCode
    this.businUnitCode = updatedGoodsReceipt.businUnitCode || this.businUnitCode
    this.items = updatedGoodsReceipt.items || this.items
    this.initialStockStatus = updatedGoodsReceipt.initialStockStatus !== undefined ? updatedGoodsReceipt.initialStockStatus : this.initialStockStatus
    this.note = updatedGoodsReceipt.note || this.note
  }

}

