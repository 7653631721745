import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment-timezone'

export default function SubscriberBasicData ({ subscriber, saveSubscriber }) {

  const [localSubscriber, setLocalSubscriber] = useState(subscriber)

  const handleChange = (event) => {
    setLocalSubscriber({
      ...localSubscriber,
      [event.target.name]: event.target.value
    })
  }

  const handleAddBankAccount = (ba) => {
    setLocalSubscriber({
      ...localSubscriber,
      bankAccounts: [...localSubscriber.bankAccounts, ba]
    })
  }

  const handleRemoveBankAccount = (ba) => {
    setLocalSubscriber({
      ...localSubscriber,
      bankAccounts: localSubscriber.bankAccounts.filter(e => e !== ba)
    })
  }

  const handleSetActive = () => {
    setLocalSubscriber({
      ...localSubscriber,
      active: !localSubscriber.active
    })
  }

  const handleSetIsIssuerInVAT = () => {
    setLocalSubscriber({
      ...localSubscriber,
      isIssuerInVAT: !localSubscriber.isIssuerInVAT
    })
  }

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              required
              id="name"
              name="name"
              label="Naziv"
              fullWidth
              value={localSubscriber.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              required
              id="responsiblePerson"
              name="responsiblePerson"
              label="Odgovorna osoba"
              fullWidth
              value={localSubscriber.responsiblePerson}
              onChange={handleChange}
            />
          </Grid>


          <Grid item xs={12} sm={6} md={2}>
            <FormControl style={{ minWidth: '100%' }}>
              <InputLabel id="taxIdentifierType">Tip obveznika</InputLabel>
              <Select
                style={{ minWidth: '100%' }}
                labelId="taxIdentifierType"
                name="taxIdentifierType"
                id="taxIdentifierType"
                value={localSubscriber.taxIdentifierType}
                onChange={handleChange}
              >
                <MenuItem value={'TIN'}>JMB/PIB</MenuItem>
                <MenuItem value={'ID'}>Licni identifikacioni broj</MenuItem>
                <MenuItem value={'PASS'}>Broj pasosa</MenuItem>
                <MenuItem value={'VAT'}>PDV broj</MenuItem>
                <MenuItem value={'TAX'}>Poreski broj</MenuItem>
                <MenuItem value={'SOC'}>Broj socijalnog osiguranja</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              autoComplete="off"
              required
              id="taxIdentifier"
              name="taxIdentifier"
              label="PIB"
              fullWidth
              value={localSubscriber.taxIdentifier}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              autoComplete="off"
              id="vatNumber"
              name="vatNumber"
              label="PDV broj"
              fullWidth
              value={localSubscriber.vatNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ChipInput
              fullWidth
              fullWidthInput
              label="Ziro računi"
              value={localSubscriber.bankAccounts}
              onAdd={(chip) => handleAddBankAccount(chip)}
              onDelete={(chip) => handleRemoveBankAccount(chip)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              required
              id="town"
              name="town"
              label="Grad"
              fullWidth
              value={localSubscriber.town}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl style={{ minWidth: '100%' }}>
              <InputLabel id="country">Država</InputLabel>
              <Select
                style={{ minWidth: '100%' }}
                labelId="country"
                id="country"
                value={localSubscriber.country}
                onChange={handleChange}
              >
                <MenuItem value={'MNE'}>Crna Gora</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              required
              id="address"
              name="address"
              label="Adresa"
              fullWidth
              value={localSubscriber.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              id="phone"
              name="phone"
              label="Telefon"
              fullWidth
              value={localSubscriber.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              id="note"
              name="note"
              label="Napomena"
              fullWidth
              value={localSubscriber.note}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
              <KeyboardDatePicker
                tabIndex={-1}
                autoOk
                disableToolbar
                size="small"
                style={{ width: 180 }}
                variant="inline"
                inputVariant="outlined"
                margin="none"
                id="date-picker-dialog-dueDate"
                label="Aktivan do"
                format="DD.MM.yyyy."
                inputValue={moment(localSubscriber.activeDueDate).format('DD.MM.yyyy.')}
                onChange={(value) => {
                  setLocalSubscriber({
                    ...localSubscriber,
                    activeDueDate: moment(value).endOf('day').tz('Europe/Podgorica').format(),
                    active: moment(value).endOf('day').isAfter(moment()) ? true : localSubscriber.active
                  })
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={<Checkbox color="secondary" name="active" checked={localSubscriber.active || false}
                                 onChange={handleSetActive}/>}
              label="Aktivan"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={<Checkbox color="secondary" name="active" checked={localSubscriber.isIssuerInVAT || false}
                                 onChange={handleSetIsIssuerInVAT}/>}
              label="PDV obveznik"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveSubscriber(localSubscriber)}
            >
              Sačuvaj
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
