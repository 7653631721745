import React from 'react';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    colors,
    makeStyles,
    useTheme
} from '@material-ui/core';
import {Business} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    }
}));

const ByStore = ({ sumsByStores, className, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();

    const colorsList = [
        theme.palette.primary.main,
        colors.red[500],
        colors.green[500],
        colors.blue[500],
        colors.grey[500],
        colors.blueGrey[500]
    ]

    const data = {
        datasets: [
            {
                data: sumsByStores.map(s => s.totalPriceWithVAT),
                backgroundColor: colorsList,
                borderWidth: 8,
                borderColor: colors.common.white,
                hoverBorderColor: colors.common.white
            }
        ],
        labels: sumsByStores.map(s => s.name)
    };

    const options = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    const stores = sumsByStores.map((s, idx) => {
                return {
                    title: s.name,
                    value: Number(s.totalPriceWithVAT.toFixed(2)),
                    icon: Business,
                    color: colorsList[idx]
                }
            });


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardHeader title="Promet po poslovnim jedinicama" />
            <Divider />
            <CardContent>
                <Box
                    height={300}
                    position="relative"
                >
                    <Doughnut
                        data={data}
                        options={options}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    mt={2}
                >
                    {stores.map(({
                                      color,
                                      icon: Icon,
                                      title,
                                      value
                                  }) => (
                        <Box
                            key={title}
                            p={1}
                            textAlign="center"
                        >
                            <Icon color="action" />
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {title}
                            </Typography>
                            <Typography
                                style={{ color }}
                                variant="h4"
                            >
                                {value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default ByStore;