import * as React from 'react'
import { Box, Button, Checkbox } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { useState } from 'react'
import Grid from '@material-ui/core/Grid'

export default function TcrUsers ({ tcr, users, subscriberUsers, postTcr }) {

  const [selectedUsers, setSelectedUsers] = useState(users)
  const columns = [
    { field: 'name', headerName: 'Ime', flex: 1 },
    { field: 'operatorCode', headerName: 'PU Code', width: 200 },
    { field: 'pin', headerName: 'PIN', width: 200 },
    { field: 'pinOnDevice', headerName: 'PIN na uredjaju', width: 200},
    {
      field: 'id',
      headerName: ' ',
      renderCell: (params) => (
        <Checkbox
          variant="contained"
          color="primary"
          size="small"
          checked={!!selectedUsers.find(e => e.id === params.row.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedUsers([
                ...selectedUsers,
                params.row
              ])
            } else {
              const newList = selectedUsers.filter(e => e.id !== params.row.id)
              setSelectedUsers(newList)
            }
          }}
          style={{ marginLeft: 16 }}
        />

      ),
    },
  ]

  return (
    <Box style={{ minHeight: 650, marginTop: 10 }}>
      <Grid item xs={12} style={{ minHeight: 450, marginBottom: 15 }}>
        <DataGrid autoHeight
                  rows={subscriberUsers.map(su => {
                    return {
                      ...su,
                      pinOnDevice: users.find(u => u.id === su.id) ? users.find(u => u.id === su.id).pin : ""
                    }
                  })}
                  columns={columns}
                  pageSize={10}
                  disableColumnMenu
                  disableMultipleSelection
                  disableSelectionOnClick
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button onClick={() => {
          postTcr({ ...tcr, users: selectedUsers })
        }} variant="contained" color="primary" style={{ marginLeft: 15 }}>
          Sačuvaj
        </Button>

      </Grid>
    </Box>
  )
}