import * as React from 'react';
import {
    Box,
    Paper,
    TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { maxNumChars, removeLineBreaks } from '../../utils/helpers'

export default function NewUser({user, setUser}) {

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeOnBlur = (name, value) => {
        setUser({
            ...user,
            [name]: value.trim()
        })
    }

    const handleSetReprintInvoice = () => {
        setUser({
            ...user,
            canReprintInvoice: !user.canReprintInvoice
        });
    };

    const handleSetAdmin = () => {
        setUser({
            ...user,
            admin: !user.admin
        });
    };

    const handleSetActive = () => {
        setUser({
            ...user,
            active: !user.active
        });
    };

    return (
        <Paper elevation={3}>
            <Box p={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete='off'
                            required
                            autoFocus
                            id="name"
                            name="name"
                            label="Ime"
                            fullWidth
                            value={user.name}
                            error={user.name.trim().length === 0}
                            helperText={`${user.name.length}/50`}
                            onChange={handleChange}
                            onBlur={(event) => {
                                handleChangeOnBlur(event.target.name, event.target.value)
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                setUser({
                                    ...user,
                                    name: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 50)
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete='off'
                            error={!user.operatorCode.match("^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}") || user.operatorCode.trim().length !== 10}
                            helperText={!user.operatorCode.match("^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}") || user.operatorCode.trim().length !== 10 ? "Unesite ispravan code" : ""}
                            required
                            id="operatorCode"
                            name="operatorCode"
                            label="PU code"
                            fullWidth
                            value={user.operatorCode}
                            onChange={handleChange}
                            onBlur={(event) => {
                                handleChangeOnBlur(event.target.name, event.target.value)
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                setUser({
                                    ...user,
                                    operatorCode: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 10)
                                })
                            }}
                        />
                    </Grid>
                    {user.id &&
                    <Grid item xs={12}>
                        <TextField
                            error={!user.pin.trim().match("^$|[0-9]{4}") || user.pin.trim().length !== 4}
                            helperText={!user.pin.trim().match("^$|[0-9]{4}") || user.pin.trim().length !== 4 ? "PIN mora imati 4 cifre" : ""}
                            required
                            id="pin"
                            name="pin"
                            label="PIN"
                            fullWidth
                            value={user.pin}
                            onChange={handleChange}
                            onBlur={(event) => {
                                handleChangeOnBlur(event.target.name, event.target.value)
                            }}
                            onPaste={(e) => {
                                e.preventDefault()
                                setUser({
                                    ...user,
                                    pin: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 4)
                                })
                            }}
                        />
                    </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox color="secondary" name="active" checked={user.active}
                                               onChange={handleSetActive}/>}
                            label="Aktivan"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox color="secondary" name="active" checked={user.admin}
                                               onChange={handleSetAdmin}/>}
                            label="Admin"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox color="secondary" name="active" checked={user.canReprintInvoice}
                                               onChange={handleSetReprintInvoice}/>}
                            label="Ponovna štampa računa"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
