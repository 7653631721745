import {CertificateModel} from "./CertificateModel";
import {UserModel} from "./UserModel";

export class TcrModel {
    constructor() {
        this.id = "";
        this.name = '';
        this.note = '';
        this.TCR = '';
        this.active = true;
        this.useGoodsReceipt = false;
        this.canOperatorUpdateGoodsReceipt = false;
        this.useCreditNotes = false;
        this.useBarcode = true;
        this.useInventoryCategorization = false;
        this.businUnitCode = '';
        this.subscriberTIN = '';
        this.cacheInvoiceCert = {};
        this.maintainerCode = process.env.REACT_APP_MAINTAINER_CODE;
        this.manufacturer = '';
        this.noncacheInvoiceCert = {};
        this.osVersion = '';
        this.serialNumber = '';
        this.softCode = process.env.REACT_APP_SOFT_CODE;
        this.storeId = '';
        this.users = [];
    }

    update(updatedTCR) {
        let cacheInvoiceCert = new CertificateModel();
        if (updatedTCR.cacheInvoiceCert !== undefined) {
            cacheInvoiceCert.update(updatedTCR.cacheInvoiceCert);
        } else {
            cacheInvoiceCert = this.cacheInvoiceCert;
        }

        let noncacheInvoiceCert = new CertificateModel();
        if (updatedTCR.noncacheInvoiceCert !== undefined) {
            noncacheInvoiceCert.update(updatedTCR.noncacheInvoiceCert);
        } else {
            noncacheInvoiceCert = this.noncacheInvoiceCert;
        }

        let users;
        if (updatedTCR.users !== undefined) {
            users = updatedTCR.users.map(u => {
                const modelUser = new UserModel();
                modelUser.update(u);
                return modelUser;
            })
        } else {
            users = this.users;
        }

        this.id = updatedTCR.id || this.id;
        this.name = updatedTCR.name || this.name;
        this.note = updatedTCR.note || this.note;
        this.TCR = updatedTCR.TCR || this.TCR;
        this.active = updatedTCR.active !== undefined ? updatedTCR.active : this.active;
        this.useGoodsReceipt = updatedTCR.useGoodsReceipt !== undefined ? updatedTCR.useGoodsReceipt : this.useGoodsReceipt;
        this.canOperatorUpdateGoodsReceipt = updatedTCR.canOperatorUpdateGoodsReceipt !== undefined ? updatedTCR.canOperatorUpdateGoodsReceipt : this.canOperatorUpdateGoodsReceipt;
        this.useCreditNotes = updatedTCR.useCreditNotes !== undefined ? updatedTCR.useCreditNotes : this.useCreditNotes;
        this.useBarcode = updatedTCR.useBarcode !== undefined ? updatedTCR.useBarcode : this.useBarcode;
        this.useInventoryCategorization = updatedTCR.useInventoryCategorization !== undefined ? updatedTCR.useInventoryCategorization : this.useInventoryCategorization;
        this.businUnitCode = updatedTCR.businUnitCode || this.businUnitCode;
        this.subscriberTIN = updatedTCR.subscriberTIN || this.subscriberTIN;
        this.cacheInvoiceCert = cacheInvoiceCert;
        this.maintainerCode = updatedTCR.maintainerCode || this.maintainerCode;
        this.manufacturer = updatedTCR.manufacturer || this.manufacturer;
        this.noncacheInvoiceCert = noncacheInvoiceCert;
        this.osVersion = updatedTCR.osVersion || this.iosVersiond;
        this.serialNumber = updatedTCR.serialNumber || this.serialNumber;
        this.softCode = updatedTCR.softCode || this.softCode;
        this.storeId = updatedTCR.storeId || this.storeId;
        this.users = users;
    }

    toJson() {
        const tcr = {...this};
        delete tcr.subscriberTIN;
        return tcr;
    }

}

