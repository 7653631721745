import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Box,
  Button,
  Grid,
  Paper
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSuccessSnackbar,
  setErrorSnackbar,
  setIsLoading,
  getCurrentTcrData,
  getCurrentTcr,
  getCurrentSubscriber
} from '../mainReducer'
import { GoodsReceiptModel } from '../../models/GoodsReceiptModel'
import GoodsReceiptItem from './GoodsReceiptItem'
import moment from 'moment-timezone'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import 'moment/locale/me'
import { GoodsReceiptItemModel } from '../../models/GoodsReceiptItemModel'
import { PlusOne } from '@material-ui/icons'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import { useApi } from '../../api/api'
import { sleep } from '../../utils/helpers'

const collator = new Intl.Collator([], { numeric: true })

export default function GoodsReceipt () {

  const { id } = useParams()
  const history = useHistory()
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentTcrData = useSelector(getCurrentTcrData)
  const [goodsReceipt, setGoodsReceipt] = useState(new GoodsReceiptModel())
  const operatorRef = useRef()
  const noteRef = useRef()
  const addItemButtonRef = useRef()
  const dispatch = useDispatch()
  const [focusFirstGoodsReceiptItem, setFocusFirstGoodsReceiptItem] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [shouldDelete, setShouldDelete] = useState(false)
  const [operator, setOperator] = useState(currentTcr.users.find(u => u.operatorCode === goodsReceipt.operatorCode) || null)
  const api = useApi()

  //if edit get goods receipt by id
  useEffect(() => {
    if (id) {
      dispatch(setIsLoading(true))
      api.getGoodsReceiptById(currentTcr.subscriberId, currentTcr.id, id)
        .then(({ data }) => {
          setGoodsReceipt(data)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [api, id, dispatch, setGoodsReceipt])

  // delete goods receipt when user confirms delete from modal
  useEffect(() => {
    if (shouldDelete) {
      deleteGoodsReceipt()
    }
  }, [shouldDelete])

  useEffect(() => {
    if (!id && currentTcr.users && currentTcr.users.length === 1) {
      setOperator(currentTcr.users[0])
    }
  }, [id, currentTcr])

  useEffect(() => {
    if (id && currentTcr.users && currentTcr.users.length > 0 && goodsReceipt && goodsReceipt.operatorCode) {
      setOperator(currentTcr.users.find(u => goodsReceipt.operatorCode === u.operatorCode))
    }
  }, [id, currentTcr, goodsReceipt])

  const editGoodsReceiptItem = (item, idx) => {
    const updatedGoodsReceiptItems = goodsReceipt.items.map((i, index) => {
      if (index === idx) {
        return item
      } else {
        return i
      }
    })

    const updatedGoodsReceipt = new GoodsReceiptModel()
    updatedGoodsReceipt.update(goodsReceipt)
    updatedGoodsReceipt.update({ items: updatedGoodsReceiptItems })
    setGoodsReceipt(updatedGoodsReceipt)
    addItemButtonRef.current.focus()
  }

  const addGoodsReceiptItem = (item) => {
    if (item) {
      const existingGoodsReceiptItem = goodsReceipt.items.find(i => i.id === item.id)
      if (!existingGoodsReceiptItem) {
        const updatedGoodsReceiptItems = [...goodsReceipt.items, item, new GoodsReceiptItemModel()]
        const updatedGoodsReceipt = new GoodsReceiptModel()
        updatedGoodsReceipt.update(goodsReceipt)
        updatedGoodsReceipt.update({ items: updatedGoodsReceiptItems })
        setGoodsReceipt(updatedGoodsReceipt)
      }
    } else {
      const updatedGoodsReceipt = new GoodsReceiptModel()
      updatedGoodsReceipt.update({
        ...goodsReceipt,
        items: [...goodsReceipt.items, new GoodsReceiptItemModel()]
      })
      setGoodsReceipt(updatedGoodsReceipt)
    }
  }

  const deleteGoodsReceiptItem = (item) => {
    const updatedGoodsReceiptItems = goodsReceipt.items.filter(i => i.id !== item.id)
    const updatedGoodsReceipt = new GoodsReceiptModel()
    updatedGoodsReceipt.update(goodsReceipt)
    updatedGoodsReceipt.update({ items: updatedGoodsReceiptItems })
    setGoodsReceipt(updatedGoodsReceipt)
  }

  const getGoodsReceiptsCount = useCallback(async () => {
    let invNumber = 0

    dispatch(setIsLoading(true))
    await api.getGoodsReceiptsMaxNum(currentTcr.subscriberId, currentTcr.id)
      .then(({ data }) => {
        const {maxOrdNum} = data;
        if (maxOrdNum && !isNaN(maxOrdNum) && Number(maxOrdNum) >= 0) {
          invNumber = Number(maxOrdNum)
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })

    return invNumber + 1
  }, [api, currentTcr.id, currentTcr.subscriberId, dispatch])

  const postGoodsReceipt = useCallback(async () => {

    const invOrdNum = await getGoodsReceiptsCount()
    if (!(invOrdNum > 0)) {
      return
    }

    const goodsReceiptToSend = new GoodsReceiptModel()
    goodsReceiptToSend.update(goodsReceipt)
    goodsReceiptToSend.update({
      invOrdNum,
      yearOfIssuance: (new Date()).getFullYear().toString(),
      businUnitCode: currentTcr.businUnitCode,
      issueDateTime: Math.floor(moment().valueOf() / 1000),
      tcrCode: currentTcr.TCR,
      items: goodsReceipt.items.filter(i => !!i.id),
      note: noteRef && noteRef.current ? noteRef.current.innerHTML : '',
      operatorCode: operator && operator.operatorCode
    })

    dispatch(setIsLoading(true))
    api.postGoodsReceipt(currentTcr.subscriberId, currentTcr.id, goodsReceiptToSend)
      .then(async () => {
        await sleep(1500).then(async () => {
          history.push(`/goods-receipts`)
        })
      })
      .catch(err => {
        dispatch(setErrorSnackbar('Greška'))
        console.log('err', err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })

  }, [api, dispatch, history, currentTcr, goodsReceipt, currentSubscriber, getGoodsReceiptsCount, operator])

  const putGoodsReceipt = useCallback(async () => {

    const goodsReceiptToSend = new GoodsReceiptModel()
    goodsReceiptToSend.update(goodsReceipt)
    goodsReceiptToSend.update({
      issueDateTime: Math.floor(moment().valueOf() / 1000),
      items: goodsReceipt.items.filter(i => !!i.id),
      note: noteRef && noteRef.current ? noteRef.current.innerHTML : '',
      operatorCode: operator && operator.operatorCode
    })

    dispatch(setIsLoading(true))
    api.putGoodsReceipt(currentTcr.subscriberId, currentTcr.id, id, goodsReceiptToSend)
      .then(async () => {
        await sleep(1500).then(async () => {
          history.push(`/goods-receipts`)
        })
      })
      .catch(err => {
        dispatch(setErrorSnackbar('Greška'))
        console.log('err', err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })

  }, [api, dispatch, history, currentTcr, goodsReceipt, currentSubscriber, operator])

  const deleteGoodsReceipt = useCallback(() => {

    dispatch(setIsLoading(true))
      api.deleteGoodsReceipt(currentTcr.subscriberId, currentTcr.id, id)
        .then(async () => {
          dispatch(setSuccessSnackbar('Prijemnica je obrisana'))
          await sleep(1500).then(async () => {
            history.push(`/goods-receipts`)
          })
        })
        .catch(err => {
          console.log('err', err)
          dispatch(setErrorSnackbar('Greška'))
        })
        .finally(() => {
          setShouldDelete(false)
          setShowDeleteModal(false)
          dispatch(setIsLoading(false))
        })

  }, [api, dispatch, history, currentTcr, goodsReceipt, currentSubscriber])

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
            <Box p={3}>
              <Grid container justify="space-between" spacing={3}>
                <Grid item xs>
                  {currentTcr && currentTcr.users &&
                  <Autocomplete
                    id="combo-box-operator"
                    size="small"
                    style={{ width: 240 }}
                    options={currentTcr && currentTcr.users ? currentTcr.users.filter(u => !u.deleted) : []}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    value={operator}
                    onChange={(event, value) => {
                      setOperator(value)
                    }}
                    renderInput={(params) =>
                      <TextField {...params}
                                 label="Operater"
                                 inputRef={operatorRef}
                                 variant="outlined"/>}/>

                  }
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
            <Box p={3} style={{ textAlign: 'center' }}>
              {currentTcrData && currentTcrData.inventory && currentTcrData.inventory.length > 0 ? goodsReceipt.items.map((item, idx) => {
                return <GoodsReceiptItem
                  key={item.id} idx={idx} editingItem={item}
                  focusFirstGoodsReceiptItem={focusFirstGoodsReceiptItem}
                  setFocusFirstGoodsReceiptItem={setFocusFirstGoodsReceiptItem}
                  inventory={currentTcrData && currentTcrData.inventory ? currentTcrData.inventory.filter(i => !i.deleted && !i.service).sort((a, b) => collator.compare(a.sku, b.sku)) : []}
                  addGoodsReceiptItem={addGoodsReceiptItem}
                  setItem={(item, idx) => {
                    let items = [...goodsReceipt.items]
                    items[idx] = item
                    setGoodsReceipt({
                        ...goodsReceipt,
                        items
                      }
                    )
                  }}
                  editGoodsReceiptItem={editGoodsReceiptItem}
                  deleteGoodsReceiptItem={deleteGoodsReceiptItem}
                  goodsReceiptItemsIds={goodsReceipt.items.filter(i => !!i.id).map(i => i.id)}
                />
              }) : null
              }
            </Box>
            <Box p={3} style={{ textAlign: 'center' }}>
              <Button
                ref={addItemButtonRef}
                disabled={goodsReceipt.items.filter(i => !i.id).length > 0}
                onClick={() => addGoodsReceiptItem()}
                variant="contained"
                size="small"
                color="default">
                <PlusOne/>
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <Box p={2}>
            <Grid container>
              <Grid item sm={12}>
                <Grid container justify="center">
                  <Box p={3}>
                    {!id ? <Button
                        disabled={
                          !goodsReceipt ||
                          goodsReceipt.items.length === 0 ||
                          !operator
                        }
                        onClick={async () => {
                          await postGoodsReceipt()
                        }}
                        variant="contained"
                        color="primary">
                        Sačuvaj
                      </Button>
                      :
                      <Button
                        disabled={
                          !goodsReceipt ||
                          goodsReceipt.items.length === 0 ||
                          !operator
                        }
                        onClick={async () => {
                          await putGoodsReceipt()
                        }}
                        variant="contained"
                        color="primary">
                        Izmijeni
                      </Button>}
                  </Box>
                  <div style={{ flex: '1 0 0' }}/>
                  <Box p={3}>

                    {id && <Button
                      disabled={
                        !goodsReceipt ||
                        goodsReceipt.items.length === 0 ||
                        goodsReceipt.operatorCode === ''
                      }
                      onClick={() => setShowDeleteModal(true)}
                      variant="contained"
                      color="default">
                      Obriši
                    </Button>}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {!!showDeleteModal &&
      <ConfirmDialog
        open={!!showDeleteModal}
        onConfirm={() => setShouldDelete(true)}
        msg={`Da li ste sigurni da želite da obrišete prijemnicu #${goodsReceipt.invOrdNum} ?`}
        closeConfirm={setShowDeleteModal}
      />
      }
    </>
  )
};
