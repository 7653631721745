import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import settings from "../containers/Setting/settingsReducer";
import main from "../containers/mainReducer"

export default configureStore({
  reducer: {
    settings: settings,
    main: main
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
  ],
});
