export class UserModel {

    constructor() {
        this.id = "";
        this.name = '';
        this.operatorCode = '';
        this.pin = '';
        this.active = true;
        this.admin = false;
        this.canReprintInvoice = true;
    }

    update(updatedUser) {
        this.id = updatedUser.id;
        this.name = updatedUser.name;
        this.operatorCode = updatedUser.operatorCode;
        this.pin = updatedUser.pin;
        this.active = updatedUser.active;
        this.admin = updatedUser.admin;
        this.canReprintInvoice = updatedUser.canReprintInvoice;
    }

}

