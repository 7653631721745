import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Menu, MenuItem } from '@material-ui/core'
import { MoreVert, Print } from '@material-ui/icons'
import { setInvoiceToPrint } from '../../containers/mainReducer'
import { useDispatch } from 'react-redux'

const ITEM_HEIGHT = 48

const InvoicesPrintItemMenu = ({ invoiceId }) => {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
    >
      <Print />
    </IconButton>
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: '300px',
        },
      }}
    >
      <MenuItem key={1} onClick={() => {
        dispatch(setInvoiceToPrint({invoiceToPrint: invoiceId, invoiceToPrintLocale:"me"}))
        handleClose()
      }
      }>
        Latinica
      </MenuItem>
      <MenuItem key={2} onClick={() => {
        dispatch(setInvoiceToPrint({invoiceToPrint: invoiceId, invoiceToPrintLocale:"sr"}))
        handleClose()
      }
      }>
        Ćirilica
      </MenuItem>
      <MenuItem key={3} onClick={() => {
        dispatch(setInvoiceToPrint({invoiceToPrint: invoiceId, invoiceToPrintLocale:"en"}))
        handleClose()
      }
      }>
        Engleski
      </MenuItem>
    </Menu>
  </div>
}

export default InvoicesPrintItemMenu