import * as React from 'react';
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper, Tab, Tabs, TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useCallback, useState} from "react";
import {getCurrentRole, getIsLoading, setErrorSnackbar, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {useAxios} from "../../utils/hooks";
import {UserModel} from "../../models/UserModel";
import NewUser from "./NewUser";
import TabPanel from "../../components/TabPanel";
import {Autocomplete} from "@material-ui/lab";
import MaterialTable from "material-table";
import {localization, tableIcons} from "../../utils/material-table";
import Edit from "@material-ui/icons/Edit";
import {Add} from "@material-ui/icons";


export default function SubscriberUsers({subscriberId, users, addUser, updateUser, subscriber, saveSubscriber}) {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(new UserModel());
    const [tabIndex, setTabIndex] = useState(0);
    const [owners, setOwners] = useState([]);
    const [accountants, setAccountants] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [localSubscriber, setLocalSubscriber] = useState(subscriber);
    const currentRole = useSelector(getCurrentRole);

    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);

    const handleChangeTabIndex = (event, newValue) => {
        setTabIndex(newValue);
    };

    const axiosInstance = useAxios();

    const handleClose = () => {
        setUser(new UserModel());
        setOpen(false);
    };

    const handleAdminOpened = (role) => {
        if (!!axiosInstance.current) {
            axiosInstance.current.get(`/admin/user/_by_role?role=${role}`)
                .then(({data}) => {
                    if (data && data.length) {
                        switch (role) {
                            case "OWNER":
                                setOwners(data.map(o => {
                                    return {
                                        id: o.id,
                                        name: `${o.firstName} ${o.lastName}`,
                                        email: o.username
                                    }
                                }))
                                break;
                            case "SELLER":
                                setSellers(data.map(o => {
                                    return {
                                        id: o.id,
                                        name: `${o.firstName} ${o.lastName}`,
                                        email: o.username
                                    }
                                }))
                                break;
                            case "ACCOUNTANT":
                                setAccountants(data.map(o => {
                                    return {
                                        id: o.id,
                                        name: `${o.firstName} ${o.lastName}`,
                                        email: o.username
                                    }
                                }))
                                break;
                            default:

                        }

                    }
                })
                .catch(err => {
                    console.log(err);
                    dispatch(setErrorSnackbar("Greška"));
                })
                .finally(() => {
                    // dispatch(setIsLoading(false));
                });
        }
    }

    const postUser = useCallback(() => {
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            // check if user info is correct
            if (user.id && (!user.pin || (user.pin && user.pin.length !== 4))) {
                dispatch(setErrorSnackbar("PIN mora imati 4 cifre!"));
                dispatch(setIsLoading(false));
                return;
            }

            if (user.id) {
                const userWithSamePIN = users.find(u => u.pin === user.pin && u.id !== user.id);
                if (userWithSamePIN) {
                    dispatch(setErrorSnackbar("Postoji operater sa istim pinom!"));
                    dispatch(setIsLoading(false));
                    return;
                }
                axiosInstance.current.put(`/admin/subscriber/${subscriberId}/user/${user.id}`, user)
                    .then(() => {
                        updateUser(user);
                        setUser(new UserModel());
                        dispatch(setSuccessSnackbar("Operater je izmijenjen"));
                        handleClose();
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            } else {
                axiosInstance.current.post(`/admin/subscriber/${subscriberId}/user`, user)
                    .then(({data}) => {
                        addUser(data);
                        setUser(new UserModel());
                        dispatch(setSuccessSnackbar("Operater je sačuvan"));
                        handleClose();
                    })
                    .catch(err => {
                        console.log(err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        }
    }, [axiosInstance, dispatch, user, subscriberId, addUser, updateUser, users]);

    const columns = [
        {field: 'name', title: 'Ime', flex: 1},
        {field: 'operatorCode', title: 'PU Code', width: 200},
        {field: 'pin', title: 'PIN', width: 200}
    ];

    return (
        <Paper elevation={3}>
            <Box>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabIndex}
                        onChange={handleChangeTabIndex}
                        aria-label="tabs operateri"
                        scrollButtons="on"
                        variant="scrollable"
                    >
                        <Tab label="OPERATERI"/>
                        <Tab label="ADMINISTRATORI"/>
                    </Tabs>
                </AppBar>
                <TabPanel value={tabIndex} index={0}>
                    <Box p={2}>
                        <div style={{minHeight: 350, width: '100%'}}>
                            <MaterialTable
                                title="Operateri"
                                data={users}
                                columns={columns}
                                icons={tableIcons}
                                localization={localization}
                                options={{
                                    rowStyle: rowData => {
                                        if (!rowData.deleted) {
                                            return {};
                                        }
                                        return {backgroundColor: '#ffbaba'};
                                    },
                                    pageSize: 10,
                                    emptyRowsWhenPaging: true,
                                    pageSizeOptions:[5, 10, 25],
                                    actionsColumnIndex: 3
                                }}
                                actions={[
                                    {
                                        icon: () => <Add />,
                                        tooltip: 'Dodaj novog operatera',
                                        isFreeAction: true,
                                        onClick: () => setOpen(true)
                                    },
                                    {
                                        icon: () => <Edit />,
                                        tooltip: 'Izmijeni',
                                        onClick: ((event, rowData) => {
                                            const editingUser = {...rowData};
                                            delete editingUser.tableData;
                                            setUser(editingUser);
                                            setOpen(true);
                                        }),
                                        position: "row"
                                    }
                                ]}
                            />
                        </div>
                    </Box>

                    <Dialog disableBackdropClick open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                            maxWidth={'md'}>
                        <DialogTitle id="form-dialog-title">{user.id ? 'Unos novog operatera' : 'Izmjena operatera'}</DialogTitle>
                        <DialogContent>
                            <NewUser
                                user={user}
                                setUser={setUser}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                              disabled={isLoading}
                              onClick={handleClose}
                              variant="outlined"
                              color="primary">
                                Zatvori
                            </Button>
                            <Button
                                    disabled={isLoading ||
                                    user.name.trim() === '' ||
                                    user.operatorCode.trim() === '' ||
                                    !user.operatorCode.match("^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}") ||
                                    user.operatorCode.trim().length !== 10 ||
                                    (user.id && (!user.pin.trim().match("^$|[0-9]{4}") || user.pin.trim().length !== 4))}
                                    onClick={postUser} variant="contained" color="primary">
                                Sačuvaj
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Box style={{minHeight: 450}} p={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="combo-box-owner"
                                    options={owners}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option.name}${option.email.trim() ? ' (' + option.email.trim() + ')' : ''}`}
                                    style={{width: 300}}
                                    onOpen={() => handleAdminOpened('OWNER')}
                                    onChange={(event, value) => {
                                        setLocalSubscriber({
                                            ...localSubscriber,
                                            owner: value
                                        })
                                    }}
                                    defaultValue={subscriber.owner}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   label="Vlasnik"
                                                   variant="outlined"/>}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="combo-box-accountant"
                                    options={accountants}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option.name}${option.email.trim() ? ' (' + option.email.trim() + ')' : ''}`}
                                    style={{width: 300}}
                                    onOpen={() => handleAdminOpened('ACCOUNTANT')}
                                    onChange={(event, value) => {
                                        setLocalSubscriber({
                                            ...localSubscriber,
                                            accountant: value
                                        })
                                    }}
                                    defaultValue={subscriber.accountant}
                                    renderInput={(params) => <TextField {...params} label="Racunovodja"
                                                                        variant="outlined"/>}/>
                            </Grid>
                            {currentRole === "ADMIN" &&
                            <Grid item xs={12} sm={6} md={4}>
                                <Autocomplete
                                    id="combo-box-seller"
                                    options={sellers}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => `${option.name}${option.email.trim() ? ' (' + option.email.trim() + ')' : ''}`}
                                    style={{width: 300}}
                                    onOpen={() => handleAdminOpened('SELLER')}
                                    onChange={(event, value) => {
                                        setLocalSubscriber({
                                            ...localSubscriber,
                                            seller: value
                                        })
                                    }}
                                    defaultValue={subscriber.seller}
                                    renderInput={(params) => <TextField {...params} label="Prodavac"
                                                                        variant="outlined"/>}/>
                            </Grid>
                            }
                            <Grid item xs={12} style={{textAlign: 'right'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => saveSubscriber(localSubscriber)}
                                >
                                    Sačuvaj
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
            </Box>

        </Paper>
    );
}
