import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { getCurrentSubscriber, getCurrentTcr } from '../../containers/mainReducer'

const ITEM_HEIGHT = 48

const InvoicesListItemMenu = ({ invoice, setInvoiceToCancel, setInvoiceToRetry }) => {

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const externalURL = invoice.fullInvoice && invoice.fullInvoice.response && invoice.fullInvoice.response.body ?
    invoice.fullInvoice.response.body.externalURL
    : (invoice.fullInvoice.response && invoice.fullInvoice.response.error
      ? invoice.fullInvoice.response.error.externalURL
      : '')

  return <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
    >
      <MoreVert/>
    </IconButton>
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: '300px',
        },
      }}
    >
      {externalURL && <MenuItem key={1} onClick={(event) => {
        event.preventDefault()
        window.open(externalURL)
      }
      }>
        Provjera na portalu Uprave prihoda
      </MenuItem>
      }
      {invoice && !invoice.corrected && invoice.typeOfInv === 'NONCASH' && invoice.status === 'SUCCESS' && setInvoiceToCancel &&
      !(!currentTcr.active || !currentSubscriber.active ||
      (
        currentSubscriber.activeDueDate &&
        moment(currentSubscriber.activeDueDate).tz('Europe/Podgorica').isBefore(moment().tz('Europe/Podgorica'))
      )) &&
      <MenuItem key={2} onClick={() => {
        setInvoiceToCancel(invoice.fullInvoice)
        handleClose()
      }
      }>
        Storniranje fakture
      </MenuItem>
      }
      {invoice && invoice.status === 'STALLED' && setInvoiceToRetry &&
      <MenuItem key={3} onClick={() => {
        setInvoiceToRetry(invoice.fullInvoice)
        handleClose()
      }
      }>
        Fiskalizovanje fakture
      </MenuItem>
      }
    </Menu>
  </div>
}

export default InvoicesListItemMenu