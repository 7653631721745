import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import MaterialTable from 'material-table'
import { v4 as uuidv4 } from 'uuid'
import CSVReader from 'react-csv-reader'
import { DataGrid } from '@material-ui/data-grid'
import { Add, CloudUpload, Delete } from '@material-ui/icons'
import { localization, tableIcons } from '../../utils/material-table'
import Edit from '@material-ui/icons/Edit'
import ProductDialog from '../TcrData/ProductDialog'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getUser } from '../mainReducer'

export default function TcrInventory ({
  inventory,
  postInventory,
  addInventory,
  updateInventory,
  importInventoryFromCsv,
  useGoodsReceipt,
  isIssuerInVAT
}) {

  const [open, setOpen] = React.useState(false)
  const [convertedData, setConvertedData] = React.useState([])
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [editingProduct, setEditingProduct] = useState(null)
  const [productToDelete, setProductToDelete] = useState(null)
  const currentUser = useSelector(getUser)

  const handleClose = () => {
    setConvertedData([])
    setOpen(false)
  }

  useEffect(() => {
    setOpenProductDialog(!!editingProduct)
  }, [editingProduct])

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`
    const a = document.createElement('a')
    a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }))
    a.setAttribute('download', finalFileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const columns = [
    { field: 'sku', title: 'Šifra', headerName: 'Šifra', width: 150 },
    { field: 'name', title: 'Naziv', headerName: 'Naziv', flex: 1 },
    { field: 'price', title: 'Cijena', headerName: 'Cijena', width: 150, type: 'numeric' },
    {
      field: 'taxRate', title: 'Porez', headerName: 'Porez', width: 150,
      initialEditValue: 21,
      lookup: { 0: '0%', 7: '7%', 21: '21%' },
      validate: rowData => !rowData || (!rowData.taxRate || rowData.taxRate.toString().trim().length === 0) ? {
        isValid: false,
        helperText: 'Ne smije biti prazno polje'
      } : true
    },
    { field: 'unitOfMeasure', title: 'JM', headerName: 'JM', width: 150 }
  ]

  const deleteProduct = async () => {
    const deletedInventory = {
      ...productToDelete,
      deleted: true,
      updatedAt: moment().valueOf(),
      deletedBy: currentUser.name
    }
    delete deletedInventory.tableData;

    await postInventory(deletedInventory).then(() => {
      updateInventory(deletedInventory)
    }).finally(() => {
      setProductToDelete(null)
    })
  }

  return (
    <Box style={{ minHeight: 650, marginTop: 10 }}>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={[
          {
            field: 'sku',
            title: 'Šifra',
            headerName: 'Šifra',
            width: 150,
            validate: rowData => !rowData || (!rowData.sku || rowData.sku.trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          {
            field: 'name',
            title: 'Naziv',
            headerName: 'Naziv',
            flex: 1,
            validate: rowData => !rowData || (!rowData.name || rowData.name.trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          {
            field: 'price',
            title: 'Cijena',
            headerName: 'Cijena',
            width: 150,
            type: 'numeric',
            validate: rowData => !rowData || (!rowData.price || rowData.price.toString().trim().length === 0) ? {
              isValid: false,
              helperText: 'Unesite ispravnu vrijednost'
            } : true
          },
          {
            field: 'taxRate',
            title: 'Porez',
            headerName: 'Porez',
            width: 150,
            lookup: { 0: '0%', 7: '7%', 21: '21%' },
            validate: rowData => !rowData || (!rowData.taxRate || rowData.taxRate.toString().trim().length === 0) ? {
              isValid: false,
              helperText: 'Ne smije biti prazno polje'
            } : true
          },
          { field: 'unitOfMeasure', title: 'JM', headerName: 'JM', width: 150 }
        ]}
        data={(inventory && inventory.length > 0 ? inventory : []).sort(function (a, b) {
          return a.sku.toString().localeCompare(b.sku, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        })}
        options={{
          rowStyle: rowData => {
            if (!rowData.deleted) {
              return {}
            }
            return { backgroundColor: '#ffbaba' }
          },
          pageSize: 10,
          emptyRowsWhenPaging: true,
          pageSizeOptions: [5, 10, 25],
          actionsColumnIndex: -1,
          exportButton: true,
          exportCsv: (columns, data) => {
            const headerRow = ["sku", "name", "price", "taxRate", "unitOfMeasure"];
            const dataRows = data.map( d => [d.sku, d.name, Number(d.price), Number(d.taxRate), d.unitOfMeasure]);
            const delimiter = ',';
            const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n')
            const csvFileName = "Artikli";
            downloadCsv(csvContent, csvFileName)
          }
        }}
        localization={localization}
        actions={[
          {
            icon: CloudUpload,
            tooltip: 'Import CSV/Excel',
            isFreeAction: true,
            onClick: () => setOpen(true)
          },
          {
            icon: () => <Add/>,
            tooltip: 'Dodaj novi',
            onClick: ((event, rowData) => {
              //setEditingProduct(rowData)
              setOpenProductDialog(true)
            }),
            isFreeAction: true,
          },
          {
            icon: () => <Edit/>,
            tooltip: 'Izmijeni',
            onClick: ((event, rowData) => {
              setEditingProduct(rowData)
            }),
            position: 'row'
          },
          {
            icon: () => <Delete/>,
            tooltip: 'Obriši',
            onClick: ((event, rowData) => {
              setProductToDelete(rowData)
            }),
            position: 'row'
          },
        ]}
        /*editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const newInventory = {
                ...newData,
                unitOfMeasure: newData.unitOfMeasure || 'kom',
                createdAt: new Date().valueOf(),
                id: uuidv4()
              }
              postInventory(newInventory)
                .then(() => {
                  addInventory(newInventory)
                  resolve()
                })
                .catch(() => {
                  reject()
                })
            }),
          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              const updatedInventory = {
                ...newData,
                updatedAt: new Date().valueOf()
              }
              postInventory(updatedInventory)
                .then(() => {
                  updateInventory(updatedInventory)
                  resolve()
                }).catch(() => {
                reject()
              })
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const deletedInventory = {
                ...oldData,
                deleted: true,
                updatedAt: new Date().valueOf()
              }
              postInventory(deletedInventory).then(() => {
                updateInventory(deletedInventory)
                resolve()
              }).catch(() => {
                reject()
              })
              resolve()
            }),
        }}*/
      />

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} fullScreen
              maxWidth={'md'}>
        <DialogTitle id="form-dialog-title">Import artikala iz csv/excel fajla</DialogTitle>
        <DialogContent>
          <Box style={{ minHeight: 650 }}>
            <DataGrid autoHeight
                      rows={convertedData}
                      columns={columns}
                      pageSize={10}
                      disableColumnMenu
                      disableMultipleSelection
                      disableSelectionOnClick
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CSVReader
            parserOptions={{
              header: true,
              dynamicTyping: true,
              skipEmptyLines: true
            }}
            onFileLoaded={(data) => {
              let dataCorrect = true
              let wrongLine
              for (let [idx, e] of data.entries()) {
                if (!e.sku || !e.name || e.price === undefined || e.taxRate === undefined || isNaN(e.price) || isNaN(e.taxRate)) {
                  dataCorrect = false
                  wrongLine = idx
                  break
                }
              }
              if (dataCorrect) {
                const convData = data.map(e => {
                  return {
                    id: uuidv4(),
                    sku: e.sku.toString(),
                    name: e.name.toString(),
                    price: Number(e.price),
                    taxRate: Number(e.taxRate),
                    unitOfMeasure: e.unitOfMeasure ? e.unitOfMeasure.toString() : 'kom'
                  }
                })
                setConvertedData(convData)
              } else {
                alert(`Podaci nisu ispravni. Greška u redu ${wrongLine + 2}`)
              }
            }}
          />
          <Button onClick={handleClose} variant="outlined" color="primary">
            Zatvori
          </Button>
          <Button
            onClick={() => {
              importInventoryFromCsv(convertedData)
              setConvertedData([])
              setOpen(false)
            }}
            variant="contained"
            color="primary"
            disabled={convertedData.length === 0}
          >
            Uvezi
          </Button>
        </DialogActions>
      </Dialog>

      {openProductDialog && <ProductDialog
        openProductDialog={openProductDialog}
        setOpenProductDialog={setOpenProductDialog}
        editingProduct={editingProduct}
        setEditingProduct={setEditingProduct}
        inventory={inventory}
        postInventory={(e, isNew, closeDialog) => {
          if (isNew) {
            const newInventory = {
              ...e,
              unitOfMeasure: e.unitOfMeasure || 'kom',
              createdAt: moment().valueOf(),
              creator: currentUser.name,
              id: uuidv4()
            }
            postInventory(newInventory).then(() => {
              addInventory(newInventory)
            }).finally(() => {
              if (closeDialog) {
                setEditingProduct(null)
              }
            })
          } else {
            const updatedInventory = {
              ...e,
              unitOfMeasure: e.unitOfMeasure || 'kom',
              modifier: currentUser.name,
              updatedAt: moment().valueOf()
            }
            postInventory(updatedInventory).then(() => {
              updateInventory(updatedInventory)
            }).finally(() => {
              setEditingProduct(null)
            })
          }

        }}
        useGoodsReceipt={useGoodsReceipt}
        isIssuerInVAT={isIssuerInVAT}
        showIsDeleted
      />}

      {!!productToDelete &&
      <ConfirmDialog
        open={!!productToDelete}
        onConfirm={deleteProduct}
        msg={`Da li ste sigurni da želite da obrišete arikal ${productToDelete.name} ?`}
        closeConfirm={setProductToDelete}
      />
      }

    </Box>
  )
}