import * as React from 'react';
import {
    AppBar,
    Box,
    Tab, Tabs
} from "@material-ui/core";
import {useCallback, useEffect, useState} from "react";
import TabPanel from "../../components/TabPanel";
import TcrBasicData from "./TcrBasicData";
import TcrInventory from "./TcrInventory";
import {setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch} from "react-redux";
import {useAxios} from "../../utils/hooks";
import TcrClients from "./TcrClients";
import TcrUsers from "./TcrUsers";
import TcrEod from "./TcrEod";
import moment from "moment";
import {InventoryModel} from "../../models/InventoryModel";

export default function Tcr({initialTcr, postTcr, registerTcr, certificates, subscriberId, generatePin, users, businUnitCode, subscriberTIN, isIssuerInVAT}) {

    const [tcr, setTcr] = useState(initialTcr);
    const [countOfTcrInitialCreation, setCountOfTcrInitialCreation] = useState(0);

    const handleChange = (event) => {
        setTcr({
            ...tcr,
            [event.target.name]: event.target.value
        });
    };

    const handleToggleBoolAttr = (event) => {
        setTcr({
            ...tcr,
            [event.target.name]: !tcr[event.target.name]
        });
    };

    useEffect(() => {
        setTcr(initialTcr);
    }, [initialTcr]);

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const dispatch = useDispatch();
    const axiosInstance = useAxios();

    const [tcrData, setTcrData] = useState({});

    const addInventory = (item) => {
        setTcrData({
            ...tcrData,
            inventory: [item, ...tcrData.inventory]
        })
    }

    const updateInventory = (item) => {
        setTcrData({
            ...tcrData,
            inventory: tcrData.inventory.map(i => {
                return i.id === item.id ? item : i;
            })
        })
    }

    const addClient = (client) => {
        setTcrData({
            ...tcrData,
            clients: [client, ...tcrData.clients]
        })
    }

    const updateClient = (client) => {
        setTcrData({
            ...tcrData,
            clients: tcrData.clients.map(i => {
                return i.id === client.id ? client : i;
            })
        })
    }

    const deleteEod = (eodId) => {
        const data = {
            ...tcrData,
            eod: tcrData.eod.filter(e => e.id !== eodId)
        };
        setTcrData(data)
    }

    const createInitialTcr = useCallback(() => {
        dispatch(setIsLoading(true));
        const initialTcr = {
            'inventory': [],
            'clients': [],
            'eod': [],
            'categories': [],
            'subscriberId': subscriberId
        }
        if (!!axiosInstance.current) {
            axiosInstance.current.post(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata`, initialTcr)
                .then(() => {
                    dispatch(setSuccessSnackbar("Kreirani inicijalni ENU podaci"));
                    getTcrs();
                })
                .catch(err => {
                    console.log('err', err);
                })
                .finally(() => {
                    setCountOfTcrInitialCreation(1);
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, subscriberId, tcr.id]);

    const getTcrs = useCallback(() => {
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            axiosInstance.current.get(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata`)
                .then(response => {
                    setTcrData(response.data);
                })
                .catch(() => {
                    if (countOfTcrInitialCreation === 0) {
                        console.info('No TCR data - create initial...');
                        createInitialTcr();
                    }
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, subscriberId, tcr.id, countOfTcrInitialCreation, createInitialTcr]);

    useEffect(() => {
        if (tcr.id) {
            getTcrs();
        }
    }, [tcr.id, getTcrs]);

    const postInventory = (i) => {
        const inventory = new InventoryModel();
        inventory.update(i);
        return axiosInstance.current.put(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata/_inventory`, inventory);
    };

    const inventoryImport = (inventory) => {
        return axiosInstance.current.put(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata`, {
            ...tcrData,
            inventory,
        });
    };

    const postClient = (c) => {
        return axiosInstance.current.put(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata/_clients`, c);
    };

    const postEod = (eod) => {
        return axiosInstance.current.put(`/inventory/subscriber/${subscriberId}/tcr/${tcr.id}/tcrdata/_eod`, eod);
    };

    const importInventoryFromCsv = async (list) => {
        dispatch(setIsLoading(true));
        let dataToImport = (tcrData.inventory && tcrData.inventory.length > 0 ? tcrData.inventory : []).map(i => {
            if (i.name && !isNaN(i.price) && i.sku && i.taxRate && !isNaN(i.taxRate) && Number(i.taxRate) > 0)
            return {
                createdAt: i.createdAt,
                creator: i.creator,
                deleted: i.deleted,
                id: i.id,
                modifier: i.modifier,
                name: i.name.toString(),
                price: i.price,
                quantity: i.quantity,
                sku: i.sku !== undefined ? i.sku.toString() : null,
                taxRate: i.taxRate,
                unitOfMeasure: i.unitOfMeasure || i.unitOfMeasure,
                updatedAt: i.updatedAt
            }
        });
        const ids = new Set(list.map(d => d.sku));
        const existingIds = new Set(dataToImport.filter(d => !!d).map(d => d.sku.toString()));
        const merged = [
            ...dataToImport.filter(d => !!d).filter(d => !ids.has(d.sku)),
            ...list.map(d => {
                const currInv = tcrData.inventory.find(i => i.sku.toString() === d.sku.toString());
            return {
                ...d,
                id: existingIds.has(d.sku.toString) && currInv && currInv.id ? currInv.id : d.id,
                createdAt: moment().valueOf(),
                updatedAt: moment().valueOf(),
                deleted: false
            }
        })];

        await inventoryImport(merged).finally(() => dispatch(setIsLoading(false)));

        getTcrs();
    };

    return (
        <Box>
            <AppBar position="static" color="transparent">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabIndexChange}
                    aria-label="tabs tcr"
                    scrollButtons="on"
                    variant="scrollable"
                >
                    <Tab label="OSNOVNI PODACI"/>
                    {tcr.id && <Tab label="OPERATERI"/>}
                    {tcr.id && <Tab label="ARTIKLI"/>}
                    {tcr.id && <Tab label="PARTNERI"/>}
                    {tcr.id && <Tab label="KRAJ DANA"/>}
                    }
                </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
                <TcrBasicData tcr={tcr} setTcr={setTcr} certificates={certificates} handleChange={handleChange}
                              handleToggleBoolAttr={handleToggleBoolAttr} postTcr={postTcr} registerTcr={registerTcr}
                              generatePin={generatePin} businUnitCode={businUnitCode} subscriberTIN={subscriberTIN}/>
            </TabPanel>

            {tcr.id &&
            <TabPanel value={tabIndex} index={1}>
                {tcr.users &&
                <TcrUsers
                    tcr={tcr}
                    users={tcr.users}
                    subscriberUsers={users}
                    postTcr={postTcr}
                />
                }
            </TabPanel>
            }

            {tcr.id &&
            <TabPanel value={tabIndex} index={2}>
                {tcrData && tcrData.inventory ?
                    <TcrInventory inventory={tcrData.inventory} postInventory={postInventory}
                                  addInventory={addInventory}
                                  updateInventory={updateInventory}
                                  importInventoryFromCsv={importInventoryFromCsv}
                                  useGoodsReceipt={tcr.useGoodsReceipt}
                                  isIssuerInVAT={isIssuerInVAT}
                    />
                    : 'Nema podataka o artiklima. Kontaktirajte administratora'}
            </TabPanel>
            }

            {tcr.id &&
            <TabPanel value={tabIndex} index={3}>
                {tcrData && tcrData.clients ?
                    <TcrClients clients={tcrData.clients} postClient={postClient} addClient={addClient}
                                updateClient={updateClient}/> : 'Nema podataka o partnerima. Kontaktirajte administratora'}
            </TabPanel>
            }

            {tcr.id &&
            <TabPanel value={tabIndex} index={4}>
                {tcrData && tcrData.eod ?
                    <TcrEod
                        postEod={postEod}
                        deleteEod={deleteEod}
                        eod={tcrData.eod}/> : 'Nema podataka o izvještajima o kraju dana. Kontaktirajte administratora'}
            </TabPanel>
            }

        </Box>
    );
}
